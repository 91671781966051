import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {performedTestsAction} from '../../redux/toolkit/performedTestsSlice';
import BootstrapTable from 'react-bootstrap-table-next';
import DashboardContainer from '../sideBar/DashboardContainer';
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import Message from '../../helper/messages/Message';
import {columnsTable} from './columnsTable';
import Loading from '../../helper/Loading';
import {Link} from 'react-router-dom';
import {ArrowClockwise} from 'react-bootstrap-icons';
import c from './performedTests.module.css';

const PerformedTestsContainer = () => {
    const dispatch = useDispatch();
    const data = useSelector(state => state?.performedTestsReducer);

    const {performed_tests, status_code, status, message} = data?.performedTestsResult;
    const {loading} = data;

    useEffect(() => {
        // if (performedTestResult?.length === 0) {
        dispatch(performedTestsAction());
        // }
    }, [dispatch])

    const options = {
        custom: true,
        totalSize: performed_tests?.length
    };

    return (
        <DashboardContainer title={'Performed Tests'}>
            {
                (!loading && status === 'success' && performed_tests?.length > 0) &&
                <div className={c.performedContainers}>
                    <div>
                        <PaginationProvider pagination={paginationFactory(options)}>
                            {({paginationProps, paginationTableProps}) => {
                                return <div>
                                    <div className={`${c.performedContent} performedContent`}>
                                        <BootstrapTable
                                            keyField='id'
                                            data={performed_tests}
                                            columns={columnsTable()}
                                            {...paginationTableProps}
                                        />
                                    </div>
                                    <div className={c.sizePagination}>
                                        <SizePerPageDropdownStandalone {...paginationProps}/>
                                        <PaginationListStandalone {...paginationProps}/>
                                    </div>
                                </div>
                            }}
                        </PaginationProvider>
                    </div>
                    <div className={c.performedNewTestButton}>
                        <ArrowClockwise color={'#005773'} title={'reload'}  size={ 30} className={c.refreshButton} onClick={()=>window.location.reload()}>ref</ArrowClockwise>
                        <Link to='/newTest'>
                            <button>New Test</button>
                        </Link>
                    </div>
                </div>
            }
            <div className={c.availableTestError}>
                {
                    !loading && status !== 'error' && !performed_tests?.length && <Message message={message}/>
                }
                {
                    status === 'error' && status_code !== 200 && !loading &&
                    <Message message={message} status={status} status_code={status_code}/>
                }
                {
                    loading && <Loading/>
                }
            </div>
        </DashboardContainer>
    );
};

export default PerformedTestsContainer;
