import axios from 'axios';
import {API} from '../../api/api';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

export const loginFetchAction = createAsyncThunk(
    'loginAction',
    async function (params, {rejectWithValue}) {
        const {loginData, authLogin} = params;
        try {
            const response = await axios.post(`${API.BASE_URL}/authenticate`, JSON.stringify(loginData), {
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });
            const {data} = response;
            if ((data.status_code === 200 || data.status_code === 201) && data.status === 'success') {
                let account_name = (data?.account_name && true) ? data.account_name : 'Account';
                authLogin(data.token, account_name);
                return response.data
            } else if (data.status_code === 203 && data.status === 'success') {
                const stateData = {
                    resetToken: data.token
                };
                const url = '/resetPassword';
                window.history.pushState(stateData, '', url);
                window.location.href = url;
                return {}
            } else return rejectWithValue(response.data.message);
        } catch (error) {
            return rejectWithValue('Something is wrong, please try later.');
        }
    }
);

const loginSlice = createSlice({

    name: 'LOGIN_TOOLKIT',

    initialState: {
        loading: null,
        authResult: [],
        error: null,
        status: null
    },
    reducers: {
        logoutAction() {
            localStorage.removeItem('access_token_toxometris');
            localStorage.removeItem('account_name_toxometris');
            localStorage.removeItem('prequestid');
            window.history.pushState({}, document.title, window.location.pathname);
        },
        emptyData(state) {
            state.authResult = [];
            state.token = null;
            state.error = null;
        }
    },

    // extraReducers: (builder) => {
    //     builder.addCase(loginFetchAction.pending, (state, action) => {
    //         console.log(state)
    //         console.log(action)
    //     }).addCase(loginFetchAction.fulfilled, (state, action) => {
    //         console.log(state)
    //         console.log(action)
    //     }).addCase(loginFetchAction.rejected, (state, action) => {
    //         console.log(state)
    //         console.log(action)
    //     })
    // },

    extraReducers: {
        [loginFetchAction.pending]: (state) => {
            state.status = 'LOGIN_REQUEST';
            state.error = null;
            state.loading = true;
        },

        [loginFetchAction.fulfilled]: (state, action) => {
            state.status = 'LOGIN_SUCCESS';
            state.authResult = action.payload;
            state.loading = false;
            state.error = null;
        },

        [loginFetchAction.rejected]: (state, action) => {
            state.status = 'LOGIN_FAIL';
            state.loading = false;
            state.error = action.payload || {message: 'ERROR !!!'};
        },
    }
});

export default loginSlice.reducer;
export const {logoutAction, emptyData} = loginSlice.actions;
