import axios from 'axios';
import {API} from '../../api/api';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {parseJwt_Expired} from '../../helper/parseJwtExpired';

export const newTestCheckPakAction = createAsyncThunk(
    'newTestPrequestidAction',
    async function (body) {
        try {
            const access_token_toxometris = localStorage.getItem('access_token_toxometris');
            access_token_toxometris && parseJwt_Expired(access_token_toxometris);

            const config = {
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token_toxometris}`,
                    'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
                }
            }
            const response = await axios.post(`${API.BASE_URL}/elab/checkPAK`, body, config);
            if (response.data?.status_code === 200) {
                return response.data;
            } else {
                window.location.href = '/'
                localStorage.removeItem('access_token_toxometris')
            }
        } catch (e) {
            return {message: e?.message || 'Something went wrong, please try later'}
        }
    })

export const newTestGetPakAction = createAsyncThunk(
  'newTestGetPakAction',
  async function (requestToken) {
    try {
      const access_token_toxometris = localStorage.getItem('access_token_toxometris');
      access_token_toxometris && parseJwt_Expired(access_token_toxometris);
      const request = new XMLHttpRequest();
      request.open('POST', `${API.BASE_URL}/elab/getPAK`, false);
      request.setRequestHeader('Accept', 'application/json');
      request.setRequestHeader('Access-Control-Allow-Origin', '*');
      request.setRequestHeader('Content-Type', 'application/json');
      request.setRequestHeader('Authorization', `Bearer ${access_token_toxometris}`);
      request.setRequestHeader('Access-Control-Allow-Methods', 'GET,HEAD,OPTIONS,POST,PUT');
      request.withCredentials = true;
      request.send(JSON.stringify({
        requestToken: requestToken,
        platform_request_id: localStorage.getItem('platform_request_id')
      }));
      const response = JSON.parse(request.responseText);
      if (response?.data?.status_code !== 200) {
        console.warn(response.data.message)
      }
      return response?.data;
    } catch (e) {
      return {message: e?.message || 'Something went wrong, please try later'}
    }
})

export const newTestGetStoredSMILESAction = createAsyncThunk(
    'newTestGetStoredSMILESAction',
    async function (body) {
        localStorage.removeItem('platform_request_id');
        // localStorage.removeItem('eLabNext_link');
        try {
            const access_token_toxometris = localStorage.getItem('access_token_toxometris');
            access_token_toxometris && parseJwt_Expired(access_token_toxometris);

            const config = {
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token_toxometris}`,
                    'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
                }
            }
            const response = await axios.post(`${API.BASE_URL}/elab/getStoredSMILES`, body, config);
            return response.data
        } catch (e) {
            return {message: e?.message || 'Something wnt wrong, please try later'}
        }
    })

const newTestCheckPakSlice = createSlice({
    name: 'NEW_TEST_PREQUESTID_TOOLKIT',
    initialState: {
        loading: null,
        loadingGetPak: null,
        checkPakResult: [],
        smilesResult: [],
        error: null,
        status: null,
        statusCodeGetPak: null
    },
    reducers: {
        emptyData(state) {
            state.result = [];
            state.token = null;
            state.errorValidate = null;
        }
    },
    extraReducers: {
        [newTestGetPakAction.pending]: (state) => {
            state.loadingGetPak = true;
        },
        [newTestGetPakAction.fulfilled]: (state, action) => {
            state.statusCodeGetPak = action.payload.status_code;
            state.loadingGetPak = false;
        },
        [newTestGetPakAction.rejected]: (state, action) => {
            state.loadingGetPak = false;
        },

        [newTestCheckPakAction.pending]: (state) => {
            state.error = null;
            state.loading = true;
        },
        [newTestCheckPakAction.fulfilled]: (state, action) => {
            state.checkPakResult = action.payload;
            state.loading = false;
            state.error = null;
            localStorage.removeItem('eLabNext_link')
        },
        [newTestCheckPakAction.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload || {message: 'ERROR !!!'};
        },

        [newTestGetStoredSMILESAction.pending]: (state) => {
            state.error = null;
            state.loading = true;
        },
        [newTestGetStoredSMILESAction.fulfilled]: (state, action) => {
            state.smilesResult = action.payload;
            state.loading = false;
            state.error = null;
        },
        [newTestGetStoredSMILESAction.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload || {message: 'ERROR !!!'};
        },
    }
})

export default newTestCheckPakSlice.reducer;
export const {emptyData} = newTestCheckPakSlice.actions;
