import React, {useEffect, useState} from 'react';
import DashboardContainer from '../sideBar/DashboardContainer';
import {useNavigate} from 'react-router-dom';
import {newTestCheckPakAction, newTestGetStoredSMILESAction} from '../../redux/toolkit/newTestSlice';
import {useDispatch, useSelector} from 'react-redux';
import { logoutAction } from '../../redux/toolkit/loginSlice';
import c from './newTest.module.css';

const NewTestContainer = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const {statusCodeGetPak, checkPakResult, smilesResult} = useSelector(state => state?.newTestCheckPakReducer);

    const plReqIdLS = localStorage.getItem('platform_request_id');
    
    const [message, setMessage] = useState('');
    const [myArraySmiles, setMyArraySmiles] = useState([]);
    const [platformRequestId, setPlatformRequestId] = useState(plReqIdLS);
    const [textareaValue, setTextareaValue] = useState('');
  
    const { status_code } = checkPakResult;

    useEffect(() => {
      if (status_code === 401) {
        dispatch(logoutAction());
        navigate("/");
        window.location.reload();
      }
    }, [dispatch, status_code, navigate]);

    useEffect(()=>{
        if(checkPakResult?.status_code === 401){
            localStorage.removeItem('access_token_toxometris');
            window.location.href = '/';
        }
    },[checkPakResult?.status_code])

    useEffect(() => {
        if (platformRequestId) {
            dispatch(newTestCheckPakAction({
                'platform_request_id': platformRequestId
            }));
        }
    }, [dispatch, platformRequestId]);

    useEffect(() => {
        if (checkPakResult?.status_code === 200 && platformRequestId && checkPakResult.response) {
            window.location.href = checkPakResult.response;
        } else if (platformRequestId && checkPakResult?.status_code === 200 && !checkPakResult.response) {
            dispatch(newTestGetStoredSMILESAction({
                'platform_request_id': platformRequestId
            }))
        }
    }, [checkPakResult.response, checkPakResult?.status_code, platformRequestId, dispatch])

    useEffect(() => {
        if (platformRequestId && statusCodeGetPak === 200) {
            dispatch(newTestGetStoredSMILESAction({
                'platform_request_id': platformRequestId
            }))
        }
    }, [dispatch, platformRequestId, statusCodeGetPak])

    useEffect(() => {
        if (smilesResult.compounds?.length > 0) {
            setMyArraySmiles(smilesResult.compounds)
            setTextareaValue(smilesResult.compounds?.join('\n'));
        } else {
            setMyArraySmiles([])
            setTextareaValue('');
        }
    }, [smilesResult]);

    const handleTextareaChange = (event) => {
        if (message) {
            setMessage('');
        }
        if (platformRequestId) {
            setPlatformRequestId(null);
        }
        setTextareaValue(event.target.value);
        setMyArraySmiles(event.target.value.split('\n'));
    };

    const handleSubmit = () => {
        setPlatformRequestId(null);
        if (myArraySmiles?.length > 0 && !message && textareaValue) {
            navigate('/checkValidate', {state: myArraySmiles});
        } else setMessage('Please fill the list');
    };

    return (
        <DashboardContainer title={'New Test'}>
            <div className={c.newTestContainer}>
                <h5 style={{textAlign: 'center', fontSize: '18px'}}>
                    <span style={{color: '#034459', paddingLeft: '5px'}}> List of SMILES (one per line)</span>
                </h5>
                <textarea placeholder='Your SMILES Here...'
                          value={textareaValue}
                          onChange={handleTextareaChange}
                          rows='17' cols='80' className={c.newTestTextarea}
                />
                {message && <span style={{color: 'red', paddingTop: '5px'}}>{message}</span>}
                <div className={c.newTestButton}>
                    <button onClick={handleSubmit}>Next</button>
                </div>
            </div>
        </DashboardContainer>
    );
};

export default NewTestContainer;
