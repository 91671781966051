import React from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
import LoginContainer from '../components/login/LoginContainer';
import CreateAccountContainer from '../components/createaccount/CreateAccountContainer';
import ChooseTestContainer from '../components/choosetext/ChooseTestContainer';
import PersonalInformation from '../components/personalInformation/PersonalInformation';
import NewTestContainer from '../components/newtest/NewTestContainer';
import PerformedTestsContainer from '../components/performedTests/PerformedTestsContainer';
import AvailableAssaysContainer from '../components/availableAssays/AvailableAssaysContainer';
import ValidationResult from '../components/validationResult/ValidationResult';
import PredictValidationContainer from '../components/predictValidation/PredictValidationContainer';
import ForgotPassword from '../components/login/ForgotPassword';
import InvoiceContainer from '../components/invoice/InvoiceContainer';
import MakePayment from "../components/makePayment/MakePayment";
import ResetPassword from "../components/login/ResetPassword";
import TermsOfUse from '../components/termsOfUse/TermsOfUse';

export const useCustomRoutes = (isAuth) => {

    const platform_request_id = localStorage.getItem('platform_request_id');

    if (isAuth) {
        return (
            <Routes>
                <Route>
                    <Route path='/available' element={<AvailableAssaysContainer/>} exact/>
                    <Route path='/newTest' element={<NewTestContainer/>} exact/>
                    <Route path='/performed' element={<PerformedTestsContainer/>} exact/>
                    <Route path='/checkValidate' element={<ChooseTestContainer/>} exact/>
                    <Route path='/validationResult' element={<ValidationResult/>} exact/>
                    <Route path='/predict' element={<PredictValidationContainer/>} exact/>
                    <Route path='/personalInformation' element={<PersonalInformation/>} exact/>
                    <Route path='/invoices' element={<InvoiceContainer/>} exact/>
                    <Route path='/invoice' element={<MakePayment/>} exact/>
                    <Route path='/termsOfUse' element={<TermsOfUse/>} exact/>
                </Route>
                <Route path='*' element={<Navigate to={platform_request_id ? '/newTest' : '/available'}/>}/>
            </Routes>
        )
    }
    return (
        <Routes>
            <Route path='/' element={<LoginContainer/>} exact/>
            <Route path='/createAccount' element={<CreateAccountContainer/>} exact/>
            <Route path='/forgotPassword' element={<ForgotPassword/>} exact/>
            <Route path='/resetPassword' element={<ResetPassword/>} exact/>
            <Route path='*' element={<Navigate to='/' exact/>}/>
        </Routes>
    )
};
