import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Modal from 'react-modal';
import axios from 'axios';
import c from './login.module.css';
import {API} from "../../api/api";

const customStyles = {
    content: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '100px',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '500px',
        height: '300px'
    },
};
const ForgotPassword = () => {
    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(true);
    const [email, setEmail] = useState('');
    const [resData, setResData] = useState([]);

    const closeModal = () => {
        setIsOpen(false);
        setResData([]);
        navigate('/');
    }
    const handleChange = (e) => {
        setEmail(e.target.value);
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(`${API.BASE_URL}/forgot_password`, {username: email}).then((res) => {
            setResData(res?.data?.message);
        })
    }

    return (
        <div className={c.forgotPassword}>
            <Modal isOpen={isOpen} style={customStyles} ariaHideApp={false}>
                <div className={`${c.formLogin} ${c.formForgotPassword} formLogin `}>
                    <h5 style={{color: '#034459'}}>Specify Email Address</h5>
                    <form onSubmit={handleSubmit}>
                        <input value={email}
                               placeholder='Email'
                               name='email'
                               type='email'
                               onChange={handleChange}
                               className={c.inputField}
                               required/>
                        <div className={c.loginBtnSubmit}>
                            <button>Submit</button>
                            <button onClick={closeModal}>Close</button>
                        </div>
                    </form>
                    <div className={c.forgotPasswordMessage}>
                        <span>{resData}</span>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default ForgotPassword;
