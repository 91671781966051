import axios from 'axios';
import {API} from '../../api/api';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

export const createAccountAction = createAsyncThunk(
    'createAccountAction',
    async function (params, {rejectWithValue}) {
        const {createAccountForm} = params;
        try {
            const response = await axios.post(`${API.BASE_URL}/create_account`, JSON.stringify(createAccountForm), {
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            });
            const {data, status} = response;
            if (status === 200 && data.status === 'success') {
                return response.data
            } else return rejectWithValue(response.data);
        } catch (error) {
            return rejectWithValue('Something is wrong, please try later.');
        }
    }
);

const createAccountSlice = createSlice({
    name: 'CREATE_ACCOUNT_TOOLKIT',
    initialState: {
        loading: null,
        createAccountResult: [],
        error: null,
        status: null
    },
    reducers: {
        logoutAction() {
            localStorage.removeItem('access_token_toxometris');
        },
        emptyData(state) {
            state.createAccountResult = [];
            state.token = null;
            state.error = null;
        }
    },
    extraReducers: {
        [createAccountAction.pending]: (state) => {
            state.status = 'CREATE_ACCOUNT_REQUEST';
            state.error = null;
            state.loading = true;
        },
        [createAccountAction.fulfilled]: (state, action) => {
            state.status = 'CREATE_ACCOUNT_SUCCESS';
            state.createAccountResult = action.payload;
            state.loading = false;
            state.error = null;
        },
        [createAccountAction.rejected]: (state, action) => {
            state.status = 'CREATE_ACCOUNT_FAIL';
            state.loading = false;
            state.error = action.payload || {message: 'ERROR !!!'};
        },
    }
});

export default createAccountSlice.reducer;
export const {logoutAction, emptyData} = createAccountSlice.actions;
