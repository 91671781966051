import {useCustomRoutes} from "./routes/routes";
import {AuthContext} from './auth/AuthContext';
import {AuthHook} from "./auth/AuthHook";
import {BrowserRouter} from "react-router-dom";
import {useDispatch} from "react-redux";
import {newTestGetPakAction} from "./redux/toolkit/newTestSlice";
import { updatePlatformRequestId } from "./redux/toolkit/chooseTestValidateSlice";

function App() {
    const dispatch = useDispatch();
    const {login, logOut, account_name, access_token_toxometris} = AuthHook();

    const platform_request_id = new URLSearchParams(window.location.search).get('platform_request_id');
    if (platform_request_id) {
        dispatch(updatePlatformRequestId(platform_request_id))
        localStorage.setItem('platform_request_id', platform_request_id);
    }

    const requestToken = new URLSearchParams(window.location.search).get('requestToken');
    if (requestToken && localStorage.getItem('platform_request_id')) {
        dispatch(newTestGetPakAction(requestToken));
    }

    const isAuth = !!access_token_toxometris;
    const routes = useCustomRoutes(isAuth);

    return (
        <AuthContext.Provider value={{login, logOut, account_name, access_token_toxometris, isAuth}}>
            <div className='App'>
                <BrowserRouter>
                    {routes}
                </BrowserRouter>
            </div>
        </AuthContext.Provider>
    );
}

export default App;
