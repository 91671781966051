import React from 'react';
import c from '../sideBar/dashboard.module.css';

const Header = (props) => {
    const {title} = props;

    return (
        <div className={c.headerContainer}>
            <div className={c.headerRow}>
                <div className={c.headerContent}>
                    <div className={c.headerTitle}>
                        <h4> {title} </h4>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Header;