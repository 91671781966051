import React from 'react';
import c from "./makePayment.module.css";

const PaymentPrices = ({priceElem, selectSmiles, elem, createRequestData, handleCreateRequest}) => {
    return (
        <div className={`${c.makePaymentPrice} ${selectSmiles.includes(elem.identifier) && c.show}`}>
            {priceElem?.price?.length > 0 && priceElem.price.map((mountElem, keyMount) => {
                return <div key={keyMount} className={c.checkbox}>
                    <label>
                        {mountElem.num_compounds} compounds per
                        month- {mountElem.value}$</label>
                    <input type="checkbox"
                           name={elem.identifier}
                           tabIndex={keyMount}
                           value={mountElem.num_compounds}
                           checked={
                               createRequestData.tariffs.find(
                                   (tariff) => tariff.assay_id === elem.identifier)?.tabIndex === keyMount
                           }
                           onChange={(e) => {
                               handleCreateRequest(e, mountElem.num_compounds, mountElem.value)
                           }}/>
                </div>
            })}
        </div>
    );
};

export default PaymentPrices;
