import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {invoicesAction} from "../../redux/toolkit/invoicesSlice";
import DashboardContainer from "../sideBar/DashboardContainer";
import BootstrapTable from "react-bootstrap-table-next";
import {columnsTable} from "./columnsTable";
import DataPicker from "./DataPicker";
import {Link} from "react-router-dom";
import moment from "moment";
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
    SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";
import Message from "../../helper/messages/Message";
import Loading from "../../helper/Loading";
import c from './invoice.module.css';

const InvoiceContainer = () => {

    const dispatch = useDispatch();
    const result = useSelector(state => state?.invoicesReducer);

    const {data, status_code, status, message} = result?.data;
    const {loading} = result;

    const [fromDateState, setFromDateState] = useState(null);
    const [toDateState, setToDateState] = useState(null);

    const toRef = useRef();
    const fromRef = useRef();

    useEffect(() => {
        if (fromDateState && toDateState) {
            const fromDate = moment(fromDateState, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (zz)').format('YYYY-MM-DD HH:mm:ss');
            const toDate = new Date(toDateState).toISOString().slice(0, 19).replace('T', ' ');

            if (
                (fromRef.current?.toString() !== fromDateState.toString()
                    || toRef.current?.toString() !== toDateState.toString())
                && !fromDate.includes('GMT+') && !toDate.includes('GMT+')
            ) {
                if (!loading) {
                    dispatch(invoicesAction({
                        "issue_start_date": fromDate,
                        "issue_end_date": toDate,
                    }))
                }
                toRef.current = toDateState;
                fromRef.current = fromDateState;
            }
        }
    }, [dispatch, fromDateState, loading, toDateState]);

    const options = {
        custom: true,
        totalSize: data?.length
    };

    return (
        <DashboardContainer title='Request Invoice'>
            <button className={c.paymentMakeButton}>
                <Link to='/invoice'>
                    Request Invoice & Make A Payment
                </Link>
            </button>
            <DataPicker setFromDateState={setFromDateState} setToDateState={setToDateState}
                        toDateState={toDateState} fromDateState={fromDateState}/>
            <hr style={{margin: '0'}}/>
            {(!loading && status === 'success' && data?.length > 0) &&
                <div className={c.invoicesContainers}>
                    <div>
                        <PaginationProvider pagination={paginationFactory(options)}>
                            {({paginationProps, paginationTableProps}) => {
                                return <div>
                                    <div className={`${c.invoicesContent} invoicesContent`}>
                                        <h5 style={{textAlign: 'center', color: '#005773', margin: '10px'}}>
                                            Issued invoices
                                        </h5>
                                        <BootstrapTable
                                            keyField="id"
                                            data={data}
                                            columns={columnsTable()}
                                            {...paginationTableProps}
                                        />
                                    </div>
                                    <div className={c.sizePagination}>
                                        <SizePerPageDropdownStandalone {...paginationProps}/>
                                        <PaginationListStandalone {...paginationProps}/>
                                    </div>
                                </div>
                            }}
                        </PaginationProvider>
                    </div>
                </div>
            }
            {
                !loading && status !== 'error' && !data?.length && <Message message={message}/>
            }
            {
                status === 'error' && status_code !== 200 && !loading &&
                <Message message={message} status={status} status_code={status_code}/>
            }
            {
                loading && <Loading/>
            }
        </DashboardContainer>

    );
};

export default InvoiceContainer;
