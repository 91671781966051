import axios from 'axios';
import {API} from '../../api/api';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {parseJwt_Expired} from '../../helper/parseJwtExpired';

export const invoicesAction = createAsyncThunk(
    'invoicesAction',
    async function (params, {rejectWithValue}) {
        const access_token_toxometris = localStorage.getItem('access_token_toxometris');
        access_token_toxometris && parseJwt_Expired(access_token_toxometris);
            try {
                const access_token_toxometris = localStorage.getItem('access_token_toxometris');
                access_token_toxometris && parseJwt_Expired(access_token_toxometris);

                const config = {
                    withCredentials: true,
                    headers: {
                        'Accept': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${access_token_toxometris}`,
                        'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
                    }
                }
                const response = await axios.post(`${API.BASE_URL}/get_invoices`, JSON.stringify(params), config);
                return response.data
            } catch (e) {
                return {message: e?.message || 'Something wnt wrong, please try later'}
            }
        }
    )

        const invoicesSlice = createSlice({
            name: 'INVOICES_TOOLKIT',
            initialState: {
                loading: null,
                data: [],
                error: null,
                status: null
            },
            reducers: {},
            extraReducers: {
                [invoicesAction.pending]: (state) => {
                    state.error = null;
                    state.loading = true;
                },
                [invoicesAction.fulfilled]: (state, action) => {
                    state.data = action.payload || [];
                    state.loading = false;
                    state.error = null;
                },
                [invoicesAction.rejected]: (state, action) => {
                    state.loading = false;
                    state.data = null;
                    state.error = action.payload || {message: 'ERROR !!!'};
                },
            }
        })

        export default invoicesSlice.reducer;
// export const {logoutAction, emptyData} = loginSlice.actions;
