import React from 'react';
import c from './makePayment.module.css';

const SelectMouth = ({checkboxMouth, handleChangeMouth}) => {
    return (
        <div className={`${c.checkbox} ${c.selectMouth}`}>
            <div className={c.selectMouthElement}>
                <label>1 Month</label>
                <input type="checkbox" value={1} checked={checkboxMouth === 1} onChange={handleChangeMouth}/>
            </div>
            <div className={c.selectMouthElement}>
                <label>6 Months</label>
                <input type="checkbox" value={6} checked={checkboxMouth === 6} onChange={handleChangeMouth}/>
            </div>
            <div className={c.selectMouthElement}>
                <label>12 Months</label>
                <input type="checkbox" value={12} checked={checkboxMouth === 12} onChange={handleChangeMouth}/>
            </div>
        </div>
    );
};

export default SelectMouth;
