import {Link45deg} from "react-bootstrap-icons";

export const columnsTable = (openLabelingModal) => {
    return [
        {
            dataField: 'issue_date',
            text: 'Issue Date',
            headerStyle: (colum, colIndex) => {
                return {width: '140px', textAlign: 'center', verticalAlign: 'middle'};
            }
        }, {
            dataField: 'invoice_number',
            text: 'Invoice Number',
            classes: () => 'overFlowHiddenRow',
            headerStyle: (colum, colIndex) => {
                return {width:'300px',textAlign: 'center', overflow: 'hidden', verticalAlign: 'middle'};
            }
        }, {
            dataField: 'price',
            text: 'Price',
            classes: () => 'reportFileCenter',
            headerStyle: (colum, colIndex) => {
                return {width: '60px', textAlign: 'center', verticalAlign: 'middle'};
            }
        },  {
            dataField: 'status',
            text: 'Status',
            classes: () => 'reportFileCenter',
            headerStyle: (colum, colIndex) => {
                return {width: '80px', textAlign: 'center', verticalAlign: 'middle'};
            }
        },
        {
            dataField: 'url',
            text: 'Invoice',
            classes: () => 'reportFileCenter',
            headerStyle: (colum, colIndex) => {
                return {width: '80px', textAlign: 'center', verticalAlign: 'middle'};
            },
            formatter: (cell, row) => {
                return <a style={{fontSize: '16px', whiteSpace: 'nowrap'}}
                          aria-hidden="true"
                          rel="noreferrer"
                          target='_blank'
                          title={cell}
                          href={cell}><Link45deg size={20}/> View </a>
            },
        },
    ];
}
