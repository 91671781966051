import axios from 'axios';
import {API} from '../../api/api';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {parseJwt_Expired} from '../../helper/parseJwtExpired';

export const personalInformationAction = createAsyncThunk(
    'personalInformationAction',
    async function () {
        try {
            const access_token_toxometris = localStorage.getItem('access_token_toxometris');
            access_token_toxometris && parseJwt_Expired(access_token_toxometris);

            const config = {
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token_toxometris}`,
                    'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
                }
            }

            const response = await axios.post(`${API.BASE_URL}/get_personal_info`, {}, config);
            return response.data
        } catch (e) {
            return {message: e?.message || 'Something wnt wrong, please try later'}
        }
    })

const personalInformationSlice = createSlice({
    name: 'PERSONAL_INFORMATION_TOOLKIT',
    initialState: {
        loading: null,
        personalInformationResult: [],
        error: null,
        status: null
    },
    reducers: {},
    extraReducers: {
        [personalInformationAction.pending]: (state) => {
            state.status = 'PERSONAL_INFORMATION_REQUEST';
            state.error = null;
            state.loading = true;
        },

        [personalInformationAction.fulfilled]: (state, action) => {
            state.status = 'PERSONAL_INFORMATION_SUCCESS';
            state.personalInformationResult = action.payload;
            state.loading = false;
            state.error = null;
        },

        [personalInformationAction.rejected]: (state, action) => {
            state.status = 'PERSONAL_INFORMATION_FAIL';
            state.loading = false;
            state.personalInformationResult = [];
            state.error = action.payload || {message: 'ERROR !!!'};
        },
    }
})
export default personalInformationSlice.reducer;
