export const columnsTable = () => {

    const colspanForLastRow = (cell, row) => {
        if (!row['start_date'] && !row['end_date']) {
            return {colSpan: `2`}
        }
    }
    const hiddenColForLastRow = (cell, row) => {
        if (!row['start_date'] && !row['end_date']) {
            return {hidden: true}
        }
    }

    return [
        {
            dataField: 'assay_name',
            text: 'Assay Name',
            headerStyle: () => ({textAlign: 'center'})
        }, {
            dataField: 'start_date',
            text: 'Start Date',
            classes: () => 'overFlowHiddenRow',
            headerStyle: () => ({textAlign: 'center'}),
            formatter: (cell, row) => {
                if (!row['start_date'] && !row['end_date']) {
                    return <div title={row['long_text']} style={{textAlign: 'center', cursor: 'context-menu'}}>{row['short_text']}</div>
                } else {
                    return row['start_date']
                }
            },
            attrs: colspanForLastRow // merge two rows
        }, {
            dataField: 'end_date',
            text: 'End Date',
            headerStyle: () => ({textAlign: 'center'}),
            attrs: hiddenColForLastRow // merge two rows
        }, {
            dataField: 'compounds_left',
            text: 'Compounds Left',
            classes: () => 'reportFileCenter',
            headerStyle: () => {
                return {width: '150px', textAlign: 'center', verticalAlign: 'middle', padding: '0'};
            },
        },
    ];
}
