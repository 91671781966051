import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import Modal from 'react-modal';
import Message from '../../helper/messages/Message';
import c from './login.module.css';
import {API} from "../../api/api";

const customStyles = {
    content: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        minHeight: '100px',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '530px',
        height: '380px'
    },
};

let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*]{8,}$/

const ResetPassword = () => {

    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(true);
    const [mistake, setMistake] = useState('');
    const [responseDataMessage, setResponseDataMessage] = useState([]);
    const [passIsValid, setPassIsValid] = useState(false);
    const [resetPassReqData, setResetPassReqData] = useState({
        new_password: '',
        repeat_password: ''
    });

    useEffect(() => {
        if (resetPassReqData.new_password && regex.test(resetPassReqData.new_password)) {
            setPassIsValid(true);
            setMistake('');
        } else {
            setPassIsValid(false);
        }
    }, [resetPassReqData.new_password]);


    const stateData = window.history.state;

    const closeModal = () => {
        setIsOpen(false);
        setResponseDataMessage([]);
        navigate('/');
    }
    const handleChange = (e) => {
        e.preventDefault();
        setResetPassReqData({...resetPassReqData, [e.target.name]: e.target.value});
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if (resetPassReqData.new_password === resetPassReqData.repeat_password && passIsValid) {
            setMistake('');
            axios.post(`${API.BASE_URL}/reset_password`, {...resetPassReqData}, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${stateData?.resetToken}`,
                }
            }).then((res) => {
                setResponseDataMessage(res?.data?.message);
            })
        } else if (resetPassReqData.new_password !== resetPassReqData.repeat_password && passIsValid) {
            setMistake("Passwords don't match, please try again");
        } else {
            setMistake('Password must be at least 8 characters, including at least one number, one uppercase, and one lowercase letter');
        }
    }

    return (
        <div className={c.forgotPassword}>
            <Modal isOpen={isOpen} style={customStyles} ariaHideApp={false}>
                <div className={`${c.formLogin} formLogin`}>
                    <h5 style={{color: '#034459'}}>Update Password</h5>
                    <form onSubmit={handleSubmit}>
                        <input value={resetPassReqData.new_password}
                               placeholder='New Password'
                               name='new_password'
                               type='password'
                               onChange={handleChange}
                               className={c.inputField}
                               required/>
                        <input value={resetPassReqData.repeat_password}
                               placeholder='Repeat Password'
                               name='repeat_password'
                               type='password'
                               style={{marginTop: '15px'}}
                               onChange={handleChange}
                               className={c.inputField}
                               required/>
                        <div className={c.loginBtnSubmit}>
                            <button>Update</button>
                            <button onClick={closeModal}>Close</button>
                        </div>
                    </form>
                    {
                        mistake && <div style={{marginTop: '20px', overflow: 'auto'}}>
                            <Message message={mistake} width={'100%'} height={'10vh'}/>
                        </div>
                    }
                    {
                        (!mistake && responseDataMessage) && <div style={{marginTop: '20px', overflow: 'auto'}}>
                            <Message message={responseDataMessage} width={'100%'} height={'10vh'}/>
                        </div>
                    }
                </div>
            </Modal>
        </div>
    );
};

export default ResetPassword;
