import React, {useEffect} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

function DataPicker({toDateState, fromDateState, setFromDateState, setToDateState}) {

    useEffect(() => {
        const today = new Date();
        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        setToDateState(today);
        setFromDateState(firstDayOfMonth);
    }, [setFromDateState, setToDateState]);

    return (
        <div className='labelingHeaderDatePickers'>
            <div className='labelingPickerElem'>
                <p style={{fontSize: '17px', color: '#005773'}}>Start Date -</p>
                <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={fromDateState}
                    onChange={(date) => setFromDateState(date)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode='select'
                    minDate={new Date('01-01-2023')}
                    maxDate={new Date()}
                />
            </div>
            <div className='labelingPickerElem' style={{paddingLeft: '15px'}}>
                <p style={{fontSize: '17px', color: '#005773'}}>End Date -</p>
                <DatePicker
                    dateFormat="dd/MM/yyyy"
                    selected={toDateState}
                    onChange={(date) => setToDateState(date)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode='select'
                    minDate={new Date('01-01-2023')}
                    maxDate={new Date()}
                />
            </div>
        </div>
    );
}

export default DataPicker;
