import DashboardContainer from "../sideBar/DashboardContainer";
import c from "./termsOfUse.module.css";

const TermsOfUse = () => {
  return (
    <DashboardContainer title="Terms and Conditions">
      <div className={`${c.c4} ${c.docContent}`}>
        <p className={`${c.title} ${c.c9}`} id="h.ntg1efxnbbgf">
          <span className={`${c.c8}`}>Terms of Use</span>
        </p>
        <p className={`${c.c0}`}>
          <span className={`${c.c1}`}></span>
        </p>
        <p className={`${c.c3}`}>
          <span className={`${c.c5}`}>Terms of Use</span>
        </p>
        <p className={`${c.c2}`}>
          <span className={`${c.c1}`}>
            The utilization of the portal.toxometris.ai website (hereinafter
            referred to as the "Toxometris Portal"), managed by Toxometris Inc.,
            a US corporation, is governed by the Terms of Use (hereinafter
            referred to as the "Terms"). Your access and use of the
            functionalities offered by the "Toxometris Portal" signifies your
            agreement to comply with, and be legally bound by, these Terms.
            Should you not concur with these Terms, refrain from accessing or
            using the "Toxometris Portal". Please be aware that Toxometris Inc.
            may, at its own discretion, alter or update these Terms at any time
            without prior notification. By continuing to use the Toxometris
            Portal after modifications are made, you signify your acceptance of
            and agreement to such changes. Provided you adhere to these Terms,
            Toxometris Inc. permits you a private, non-exclusive,
            non-transferrable, restricted right to access and utilize the
            Toxometris Portal.
          </span>
        </p>

        <h2 className={`${c.c7}`} id="h.ljrs6bbcc7i8">
          <span className={`${c.c6} ${c.c5}`}>Licensed Materials</span>
        </h2>
        <p className={`${c.c2}`}>
          <span className={`${c.c1}`}>
            The Licensed Materials are provided to you based on your request on
            Toxometris Portal, which include, but are not limited to,
            predictions of physicochemical and biological parameters, toxicity
            profile, ADME parameters etc. of one or multiple small molecules.
          </span>
        </p>
        <p className={`${c.c2} ${c.c10}`}>
          <span className={`${c.c1}`}></span>
        </p>
        <p className={`${c.c2}`}>
          <span className={`${c.c1}`}>
            All information submitted to the portal (e.g. Simplified Molecular
            Input Line Entry System (SMILES), International Chemical Identifier
            (InChI), CAS Number identifiers), as well as any generated
            prediction results and reports, remain exclusive property of the
            submitter.
          </span>
        </p>
        <p className={`${c.c0}`}>
          <span className={`${c.c1}`}></span>
        </p>
        <p className={`${c.c0}`}>
          <span className={`${c.c1}`}></span>
        </p>
        <p className={`${c.c3}`}>
          <span className={`${c.c6} ${c.c5}`}>Content</span>
        </p>
        <p className={`${c.c2}`}>
          <span>
            All elements, including but not limited to text, graphics, user and
            visual interfaces, trademarks, logos, artwork, and computer code
            (hereinafter referred to as the "Content") on the Toxometris Portal,
            are the sole property of, or are licensed to, Toxometris Inc. Such
            Content is safeguarded by copyright, trademark, and laws pertaining
            to unfair competition. Unless explicitly stipulated in these Terms,
            any reproduction, transmission, modification, public display,
            uploading, posting, or distribution of the Content in any manner or
            form (inclusive of "mirroring") to any external computer, server,
            website, or any other medium for the purposes of publication,
            dissemination, or any commercial activity, is strictly prohibited
            without the express prior written authorization of Toxometris Inc.
          </span>
        </p>
        <p className={`${c.c0}`}>
          <span className={`${c.c1}`}></span>
        </p>
        <p className={`${c.c3}`}>
          <span className={`${c.c6} ${c.c5}`}>
            Confidentiality and Non-Use of Customer Data
          </span>
        </p>
        <p className={`${c.c2}`}>
          <span className={`${c.c1}`}>
            We commit to uphold the strictest standards of confidentiality with
            respect to the data and prediction results generated through this
            portal. We will not share, distribute, sell or disclose the
            Simplified Molecular Input Line Entry System (SMILES), International
            Chemical Identifier (InChI), CAS Number identifiers of submitted
            chemical, other chemical information, or prediction results
            generated by Toxometris Portal with any third parties, unless
            explicitly authorized in writing by the Customer. Moreover, we will
            not use the submitted formulae, chemical information, or generated
            prediction results for our own research, analysis, training of
            models or any other purposes, unless explicitly authorized in
            writing by the Customer.
          </span>
        </p>
        <p className={`${c.c0}`}>
          <span className={`${c.c1}`}></span>
        </p>
        {/* <p className={`${c.c0}`}>
        <span className={`${c.c1}`}></span>
      </p> */}
        <p className={`${c.c2}`}>
          <span className={`${c.c6} ${c.c5}`}>License and restrictions</span>
        </p>
        <p className={`${c.c2}`}>
          <span>Toxometris Inc.</span>
          <span className={`${c.c1}`}>
            &nbsp;grants to you a limited, non-exclusive, non-transferable,
            non-delegable, revocable, worldwide license for the Permitted Use.
            For the purpose of these Terms, "Permitted Use" shall mean the use
            of Toxometris Portal for the following purposes: (i) private study
            and (ii) internal research, whether on a commercial or
            non-commercial basis.
          </span>
        </p>
        <p className={`${c.c2} ${c.c10}`}>
          <span className={`${c.c1}`}></span>
        </p>
        <p className={`${c.c2}`}>
          <span className={`${c.c1}`}>
            You warrant, represent and undertake: (i) not to use Toxometris
            Portal in such a way that it can be damaged or overloaded ; (ii) not
            to copy or modify any portion of the Content and Licensed Materials
            in order to provide similar or identical services to Toxometris
            Portal; (iii) to comply with all applicable laws, rules and
            regulations regarding the use of the Licensed Materials; (iii) not
            to use any form of web crawler or other data retrieval tool or
            service to access Toxometris Portal in any automated manner,
            including for the purpose of automatically collecting and
            reconstructing a Substantial Part of the Licensed Materials; (iv)
            not train any machine learning, statistical or any kind of other
            models based on the prediction generated by Toxometris Portal. For
            the purposes of these Terms, a "Substantial Part of the Licensed
            Materials" shall mean any subset of the Licensed Materials
            containing more than 20% of the Licensed Materials.
          </span>
        </p>
        <p className={`${c.c2} ${c.c10}`}>
          <span className={`${c.c1}`}></span>
        </p>
        <p className={`${c.c3}`}>
          <span className={`${c.c5} ${c.c6}`}>Limitation of Liability</span>
        </p>
        <p className={`${c.c2}`}>
          <span className={`${c.c1}`}>
            It is understood that the toxicity predictions provided by the
            Toxometris Portal are based on algorithms and data models that are
            not exhaustive and do not cover all possible variables that may
            affect toxicity levels or any other physico-chemical properties of
            any particular chemical compound. These predictions are to be used
            as preliminary indicators only and should not be considered as
            definitive conclusions or advice. The Customer should consult with
            qualified experts and conduct further tests before making any
            decisions based on the predictions generated by the Toxometris
            Portal.
          </span>
        </p>
        <p className={`${c.c0}`}>
          <span className={`${c.c1}`}></span>
        </p>
        <p className={`${c.c0}`}>
          <span className={`${c.c1}`}></span>
        </p>
        <p className={`${c.c3}`}>
          <span className={`${c.c5} ${c.c6}`}>
            Suspension, Termination, and Restriction of Access
          </span>
        </p>
        <p className={`${c.c2}`}>
          <span className={`${c.c1}`}>
            Toxometris Inc. reserves the right, at its sole discretion, to
            suspend, terminate, or restrict your access to the Toxometris Portal
            and/or the Licensed Materials without prior notice or liability for
            any or no reason. Notwithstanding the aforementioned, if Toxometris
            Inc. reasonably determines that you have breached any provision of
            these Terms, such action of suspension, termination, or restriction
            may be taken.
          </span>
        </p>
        <p className={`${c.c0}`}>
          <span className={`${c.c1}`}></span>
        </p>
        <p className={`${c.c0}`}>
          <span className={`${c.c1}`}></span>
        </p>
        <p className={`${c.c3}`}>
          <span className={`${c.c5} ${c.c6}`}>Governing Law</span>
        </p>
        <p className={`${c.c2}`}>
          <span className={`${c.c1}`}>
            This disclaimer is governed by the laws of the State of Delaware
            (US), and any disputes arising from or relating to this disclaimer
            shall be subject to the exclusive jurisdiction of the courts of the
            State of Delaware (US).
          </span>
        </p>
        <p className={`${c.c2} ${c.c10}`}>
          <span className={`${c.c1}`}></span>
        </p>
        <p className={`${c.c2}`}>
          <span className={`${c.c1}`}>
            These terms of use were last updated on Sep, 2023.
          </span>
        </p>
      </div>
    </DashboardContainer>
  );
};

export default TermsOfUse;
