import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {availableAssaysAction} from '../../redux/toolkit/availableAssaysSlice';
import DashboardContainer from '../sideBar/DashboardContainer';
import Message from '../../helper/messages/Message';
import Loading from '../../helper/Loading';
import c from './availableAssays.module.css';

const AvailableAssaysContainer = () => {

    const dispatch = useDispatch();
    const dataResult = useSelector(state => state?.availableAssaysReducer);

    const {assays, status_code, status, message} = dataResult?.availableAssaysResult || {};
    const {loading} = dataResult;

    useEffect(() => {
        dispatch(availableAssaysAction({'service_type': 'all'}));
    }, [dispatch])

    return (
        <DashboardContainer title={'Available Tests'}>
            {(!loading && status === 'success' && assays?.length > 0) &&
                <div className={c.availableTestContainers}>
                    <div className={c.availableTestContent}>
                        {assays.map((i, k) => {
                            return <div key={k}>
                                <h5 style={{textAlign: 'center', fontSize: '18px'}}>
                                    <span style={{color: '#034459', paddingLeft: '5px'}}>{i?.group_name}</span>
                                </h5>
                                {
                                    i.data?.length > 0 && i.data.map((elem, key) => {
                                        return <div key={key} className={c.availableTestElem}>
                                            <div className={c.description}>
                                                <h5>
                                                <span className={c.descriptionElem}>
                                                    {elem?.name}
                                                </span>
                                                </h5>
                                                <span>{elem?.description}</span>
                                                <div>
                                                    <a style={{cursor: 'pointer'}} href={elem?.qmrf} target={'_blank'}
                                                       title={'The QSAR Model Reporting Format, which includes key information on the prediction model according to OECD principles for the validation of QSAR models'}>
                                                        QMRF</a>,
                                                    <a style={{cursor: 'pointer'}} href={elem?.sample_report} target={'_blank'}> Report sample</a>
                                                </div>
                                            </div>
                                            <div className={c.price}>
                                                <h5>
                                                    <span
                                                        style={{fontStyle: 'italic'}}>{elem?.price ? 'Price :' : ''}
                                                    </span>
                                                    <span
                                                        style={{color: '#034459', paddingLeft: '5px'}}>{elem?.price}
                                                    </span>
                                                </h5>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        })}
                    </div>
                    <div className={c.availableTestNewTestButton}>
                        <Link to='/newTest'>
                            <button>New Test</button>
                        </Link>
                    </div>
                </div>}
            {
                !loading && status !== 'error' && !assays?.length && <Message message={message}/>
            }
            {
                status === 'error' && status_code !== 200 && !loading &&
                <Message message={message} status={status} status_code={status_code}/>
            }
            {
                loading && <Loading/>
            }
        </DashboardContainer>
    );
};

export default AvailableAssaysContainer;
