import axios from 'axios';
import {API} from '../../api/api';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {parseJwt_Expired} from '../../helper/parseJwtExpired';

export const predictValidationAction = createAsyncThunk(
    'predictValidationAction',
    async function (params, {rejectWithValue}) {
        const access_token_toxometris = localStorage.getItem('access_token_toxometris');
        access_token_toxometris && parseJwt_Expired(access_token_toxometris);

        try {
            const response = await axios.post(`${API.BASE_URL}/predict`,
                JSON.stringify(params), {
                    withCredentials: true,
                    headers: {
                        'Accept': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${access_token_toxometris}`,
                        'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
                    }
                });
            const {data, status} = response;
            if (status === 200 && data.status === 'success' && data?.result?.length > 0) {
                return response.data
            } else {
                debugger
                return rejectWithValue(response.data);
            }
        } catch (error) {
            debugger
            return rejectWithValue('Something is wrong, please try later.');
        }
    }
);
const predictValidationSlice = createSlice({

    name: 'PREDICT_VALIDATION_TOOLKIT',

    initialState: {
        loading: null,
        predictValidationResult: [],
        error: null,
        status: null
    },
    reducers: {
        logoutAction() {
            localStorage.removeItem('access_token_toxometris');
        },
        emptyData(state) {
            state.predictValidationResult = [];
            state.token = null;
            state.errorValidate = null;
        }
    },

    extraReducers: {
        [predictValidationAction.pending]: (state) => {
            state.status = 'PREDICT_VALIDATION_REQUEST';
            state.error = null;
            state.loading = true;
        },

        [predictValidationAction.fulfilled]: (state, action) => {
            state.status = 'PREDICT_VALIDATION_SUCCESS';
            state.predictValidationResult = action.payload;
            state.loading = false;
            state.error = null;
        },

        [predictValidationAction.rejected]: (state, action) => {
            state.status = 'PREDICT_VALIDATION_FAIL';
            state.loading = false;
            state.predictValidationResult = [];
            state.error = action.payload || {message: 'ERROR !!!'};
        },
    }
});

export default predictValidationSlice.reducer;
export const {logoutAction, emptyData} = predictValidationSlice.actions;
