import React, {useContext, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {AuthContext} from '../../auth/AuthContext';
import {logoutAction} from '../../redux/toolkit/loginSlice';
import {useNavigate} from 'react-router-dom';
import c from '../healper.module.css'

const Message = ({message, status, status_code, color, width, height}) => {
    const dispatch = useDispatch();
    const auth = useContext(AuthContext);
    let navigate = useNavigate();

    useEffect(() => {
        if (status_code === 401) {
            dispatch(logoutAction())
            auth.logOut();
            navigate('/')
            window.location.reload();
        }
    }, [auth, dispatch, status_code, navigate])

    return (
        <div className={c.messageContainer} style={{width: width ? width : '500px', height: height ? height : '20vh'}}>
            <span style={{color: status_code === 400 || status_code === 401 ? 'red' : '#005773'}}>
                {message ? message : 'No Result'}
            </span>
        </div>
    );
};

export default Message;
