import React, {useEffect, useState} from 'react';
import DashboardContainer from '../sideBar/DashboardContainer';
import {useDispatch, useSelector} from 'react-redux';
import {availableAssaysAction} from '../../redux/toolkit/availableAssaysSlice';
import Message from '../../helper/messages/Message';
import Loading from '../../helper/Loading';
import {makePaymentAction, emptyData} from '../../redux/toolkit/makePaymentSlice';
import SmilesContent from './SmilesContent';
import PaymentPrices from './PaymentPrices';
import SelectMouth from './SelectMouth';
import c from './makePayment.module.css';

const MakePayment = () => {
    const dispatch = useDispatch();
    const dataResult = useSelector(state => state?.availableAssaysReducer);
    const dataPayment = useSelector(state => state?.makePaymentReducer);
    const {assays, status_code, status, message} = dataResult?.availableAssaysResult || {};

    const [checkboxMouth, setCheckboxMouth] = useState(1);
    const [selectSmiles, setSelectSmiles] = useState([]);
    const [createRequestData, setCreateRequestData] = useState({
        tariffs: [],
        total_price: 0,
        num_months: 1
    });

    useEffect(() => {
        dispatch(availableAssaysAction({'service_type': 'all'}));
        return () => {
            dispatch(emptyData());
        }
    }, [dispatch])

    const handleChangeSmiles = (e) => {
        if (selectSmiles.includes(e.target.value)) {
            setSelectSmiles(selectSmiles.filter((s) => s !== e.target.value));
            const existingAssay = createRequestData.tariffs.find(
                (assay) => assay.assay_id === e.target.value.toLowerCase()
            );

            setCreateRequestData({
                ...createRequestData,
                tariffs: createRequestData.tariffs.filter((a) => a.assay_id !== e.target.value),
                total_price: createRequestData?.total_price - (existingAssay.price ? existingAssay.price : 0)
            })
        } else {
            setSelectSmiles([...selectSmiles, e.target.value]);
            setCreateRequestData({
                ...createRequestData,
                tariffs: [
                    ...createRequestData.tariffs, {assay_id: e.target.value}
                ],
            })
        }
    };

    const handleCreateRequest = (event, num_compounds, price) => {

        const {name, checked, tabIndex} = event.target;

        setCreateRequestData((prevData) => {

            const {tariffs, total_price, ...otherData} = prevData;

            const existingAssay = tariffs.find(
                (assay) => assay.assay_id === name.toLowerCase()
            );

            if (existingAssay) {
                // The assay ID already exists in the tariffs array
                if (checked) {
                    // The checkbox is checked, so update the existing assay's price and num_compounds
                    existingAssay['num_compounds'] = num_compounds;
                    existingAssay['price'] = price;
                    existingAssay['tabIndex'] = tabIndex;
                } else {
                    // The checkbox is unchecked, so delete the existing assay's price and num_compounds
                    delete existingAssay['num_compounds'];
                    delete existingAssay['price'];
                    delete existingAssay['tabIndex'];
                    // If the existing assay has no prices left, remove it from the tariffs array
                    if (Object.keys(existingAssay).length === 1) {
                        return {
                            ...otherData,
                            tariffs: tariffs.filter(
                                (assay) => assay.assay_id !== existingAssay.assay_id
                            ),
                            // Calculate Total Price when unchecked
                            total_price: setCreateRequestData({...createRequestData, total_price: prevData.total_price - price, tabIndex: tabIndex})
                        };
                    }
                }
            } else {
                // The assay ID does not exist in the tariffs array
                return {
                    ...otherData,
                    tariffs: [...tariffs, {assay_id: name.toLowerCase(), num_compounds: num_compounds}],
                };
            }
            // Calculate Total Price
            const totalPrice = tariffs.reduce((acc, assay) => {
                return acc + parseInt(assay?.price ? assay.price : 0)
            }, 0);

            return {
                ...prevData,
                tariffs: [...tariffs],
                total_price: totalPrice
            };
        });
    };

    const handleChangeMouth = (event) => {
        // update mouth state
        setCheckboxMouth(parseInt(event.target.value));

        // create new object for update requestData after select mouth
        let obj = []

        // find matched selected tariffs to response data
        dataResult.availableAssaysResult.assays.forEach((elem) => {
            elem.data.forEach((i) => {
                if (selectSmiles.includes(i.identifier))
                    i.prices.forEach((e) => {
                        let indexMount = parseInt(event.target.value) === 1 ? 0 : parseInt(event.target.value) === 6 ? 1 : 2
                        if (e.num_months === parseInt(event.target.value)) {
                            const updatedTariff = createRequestData.tariffs.find(tariff => tariff.assay_id === i.identifier);
                            let ob = {
                                assay_id: i.identifier,
                                num_compounds: i.prices[indexMount]?.price[updatedTariff.tabIndex]?.num_compounds,
                                price: i.prices[indexMount]?.price[updatedTariff.tabIndex]?.value,
                                tabIndex: updatedTariff.tabIndex
                            }
                            obj.push(ob)
                        }
                    })
            })
        });

        // Calculate new Total Price

        let totalPrice = obj.reduce((acc, assay) => {
            return acc + parseInt(assay?.price ? assay.price : 0)
        }, 0);

        // Update old data request to new request data after change mount

        setCreateRequestData({...createRequestData, tariffs: obj, total_price: totalPrice});
    }

    const handleSubmit = () => {
        createRequestData.tariffs = createRequestData.tariffs.filter(tariff => tariff.num_compounds);
        // remove price from each tariff object
        createRequestData.tariffs = createRequestData.tariffs.map(tariff => {
            delete tariff.price;
            return tariff;
        });
        // create newData object with modified tariffs array and same total_price and num_months values
        let newData = {
            tariffs: createRequestData.tariffs,
            total_price: createRequestData.total_price,
            num_months: checkboxMouth,
        };
        dispatch(makePaymentAction({...newData}));
    }

    return (
        <DashboardContainer title={'Make Payment'}>
            {(!dataPayment?.loading && status === 'success' && assays?.length > 0 && dataPayment?.data?.length === 0) &&
                <div className={c.makePaymentContent}>
                    <div  className={c.bodyContainer}>
                    {assays.map((i, k) => {
                        return <div key={k}>
                            <h5 style={{textAlign: 'center', fontSize: '18px'}}>
                                <span style={{color: '#034459', paddingLeft: '5px'}}>{i?.group_name}</span>
                            </h5>
                            {dataPayment?.data?.invoice_url && <span>invoice_url: {dataPayment.data.invoice_url}</span>}
                            {i.data?.length > 0 && i.data.map((elem, key) =>
                                <div key={key} className={c.makePaymentElem}>
                                    <SmilesContent elem={elem} selectSmiles={selectSmiles} handleChangeSmiles={handleChangeSmiles}/>
                                    <div className={c.makePaymentAllPrices}>
                                        {elem?.prices?.length > 0 && elem.prices.map((priceElem, k) => {
                                                return priceElem.num_months === checkboxMouth
                                                    ? <PaymentPrices key={k}
                                                                     elem={elem}
                                                                     priceElem={priceElem}
                                                                     selectSmiles={selectSmiles}
                                                                     createRequestData={createRequestData}
                                                                     handleCreateRequest={handleCreateRequest}/>
                                                    : null
                                            }
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    })}
                    </div>
                    {
                        <div className={c.footer}>
                            <div className={c.footerLeft}>
                                <span>Total Price: {createRequestData?.total_price}</span>
                            </div>
                            <SelectMouth checkboxMouth={checkboxMouth} handleChangeMouth={handleChangeMouth}/>
                            <div className={c.makePaymentButton}>
                                {createRequestData?.total_price ? <button onClick={handleSubmit}> Submit</button> : null}
                            </div>
                        </div>
                    }
                </div>
            }
            {
                !dataPayment?.loading && status === 'success' && dataPayment?.data?.invoice_url &&
                <div className={c.viewInvoice}>
                    <span>
                        Your invoice is successfully issued and emailed. You can also download it from&nbsp;
                        <a rel="noreferrer" target='_blank' href={dataPayment?.data?.invoice_url}>here.</a>
                    </span>
                </div>
            }
            {
                !dataPayment?.loading && status !== 'error' && !assays?.length && <Message message={message}/>
            }
            {
                status === 'error' && status_code !== 200 && !dataPayment?.loading &&
                <Message message={message} status={status} status_code={status_code}/>
            }
            {
                dataPayment?.loading && <Loading/>
            }
        </DashboardContainer>
    )
};

export default MakePayment;
