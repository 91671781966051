import {Link45deg} from "react-bootstrap-icons";

export const columnsTable = (openLabelingModal) => {
    return [
        {
            dataField: 'date',
            text: 'Date',
            headerStyle: (colum, colIndex) => {
                return {textAlign: 'center', width: '180px', verticalAlign:'middle',padding:'0'};
            }
        }, {
            dataField: 'test_name',
            text: 'Test Name',
            classes: () => 'overFlowHiddenRow',
            headerStyle: (colum, colIndex) => {
                return {textAlign: 'center', overflow:'hidden', verticalAlign:'middle',padding:'0'};
            }
        }, {
            dataField: 'predicted',
            text: 'Predicted Compounds',
            classes: () => 'reportFileCenter',
            headerStyle: (colum, colIndex) => {
                return {width: '120px', textAlign: 'center', verticalAlign:'middle',padding:'0'};
            }
        }, {
            dataField: 'test_status',
            text: 'Status',
            classes: () => 'reportFileCenter',
            headerStyle: (colum, colIndex) => {
                return {width: '100px', textAlign: 'center', verticalAlign:'middle',padding:'0'};
            }
        }, {
            dataField: 'report_file',
            text: 'Report File',
            classes: () => 'migrationLogsClass reportFileCenter',
            headerStyle: (colum, colIndex) => {
                return {width: '150px', textAlign: 'center', verticalAlign:'middle',padding:'0'};
            },
            formatter: (cell, row) => {
                return <a style={{fontSize: '16px', whiteSpace: 'nowrap'}}
                          aria-hidden="true"
                          rel="noreferrer"
                          target='_blank'
                          title={cell}
                          href={cell}><Link45deg size={20}/> View </a>
            },
        },
    ];
}
