import {combineReducers, configureStore} from "@reduxjs/toolkit";
import loginSlice from "./toolkit/loginSlice";
import createAccountSlice from "./toolkit/createAccountSlice";
import availableAssaysSlice from "./toolkit/availableAssaysSlice";
import performedTestsSlice from './toolkit/performedTestsSlice';
import personalInformationSlice from "./toolkit/personalInformationSlice";
import chooseTestValidateSlice from "./toolkit/chooseTestValidateSlice";
import predictValidationSlice from "./toolkit/predictValidationSlice";
import changePasswordSlice from "./toolkit/changePasswordSlice";
import updateAccountSlice from "./toolkit/updateAccountSlice";
import newTestCheckPakSlice from "./toolkit/newTestSlice";
import invoicesSlice from "./toolkit/invoicesSlice";
import makePaymentSlice from "./toolkit/makePaymentSlice";
import activateAccountSlice from "./toolkit/activateAccountSlice";

const rootReducer = combineReducers({
    loginReducer: loginSlice,
    createAccountReducer: createAccountSlice,
    availableAssaysReducer: availableAssaysSlice,
    performedTestsReducer: performedTestsSlice,
    personalInformationReducer: personalInformationSlice,
    chooseTestValidateReducer: chooseTestValidateSlice,
    predictValidationReducer: predictValidationSlice,
    changePasswordReducer: changePasswordSlice,
    updateAccountReducer: updateAccountSlice,
    newTestCheckPakReducer: newTestCheckPakSlice,
    invoicesReducer: invoicesSlice,
    makePaymentReducer: makePaymentSlice,
    activateAccountReducer: activateAccountSlice,
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});
