import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {changePasswordAction} from '../../redux/toolkit/changePasswordSlice';
import c from './personalInformation.module.css';

let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;

const ChangePassword = ({username}) => {
    const dispatch = useDispatch();

    const result = useSelector(state => state?.changePasswordReducer?.message);
    const [mistake, setMistake] = useState('');
    const [passIsValid, setPassIsValid] = useState(false);
    const [changePassForm, setChangePassForm] = useState({
        current_password: '',
        new_password: '',
        repeat_password: ''
    });

    useEffect(() => {
        if (changePassForm.new_password && regex.test(changePassForm.new_password)) {
            setPassIsValid(true);
            setMistake('');
        } else {
            setPassIsValid(false);
        }
    }, [changePassForm.new_password]);

    const handleChange = (e) => {
        if (mistake) {
            setMistake('');
        }
        setChangePassForm({...changePassForm, [e.target.name]: e.target.value})
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (changePassForm.new_password === changePassForm.repeat_password
            && changePassForm.new_password !== changePassForm.current_password
            && passIsValid) {
            setMistake('');
            dispatch(changePasswordAction({...changePassForm, username: username}));
        } else if (changePassForm.new_password !== changePassForm.repeat_password && passIsValid) {
            setMistake("Passwords don't match, please try again");
        } else if (changePassForm.new_password === changePassForm.current_password) {
            setMistake('The current password must not be the same as the new password');
        } else {
            setMistake('Password must be at least 8 characters, including at least one number, one uppercase, and one lowercase letter');
        }
    }

    return (
        <div className={c.personalInfoChangePassContent}>
            <form onSubmit={handleSubmit}>
                <input type='password' placeholder='Current Password' name='current_password'
                       onChange={handleChange} required className={c.inputField}
                       autoComplete='new-password'/>
                <input type='password' placeholder='New Password' name='new_password'
                       onChange={handleChange} required className={c.inputField}
                       autoComplete='new-password'/>
                <input type='password' placeholder='Repeat New Password' name='repeat_password'
                       onChange={handleChange} required className={c.inputField}
                       autoComplete='new-password'/>
                <div className={c.personalInfoBtn}>
                    <button>Change Password</button>
                </div>
            </form>
            {
                (result?.status === 'success' && result?.message)
                && <span className={c.successSpan}>{result?.message}</span>
            }
            {
                (result?.status === 'error' && result?.message)
                && <span className={c.mistakeSpan}>{result?.message}</span>
            }
            {
                mistake && <span className={c.mistakeSpan}> {mistake} </span>
            }
        </div>
    )
};

export default ChangePassword;
