import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link, useLocation} from "react-router-dom";
import {predictValidationAction} from "../../redux/toolkit/predictValidationSlice";
import DashboardContainer from "../sideBar/DashboardContainer";
import Message from "../../helper/messages/Message";
import Loading from "../../helper/Loading";
import c from "./predictValidation.module.css";

const PredictValidationContainer = () => {
    let location = useLocation();
    const dispatch = useDispatch();
    const predictValidationData = useSelector(state => state?.predictValidationReducer);
    const {result, status_code, status, message} = predictValidationData?.predictValidationResult;
    const {loading} = predictValidationData;

    const platform_request_id = localStorage.getItem('platform_request_id')

    useEffect(() => {
        if (location.state) {
            dispatch(predictValidationAction(location.state));
        }
        return () => {
            // localStorage.removeItem('platform_request_id');
            // localStorage.removeItem('requestToken');
            // localStorage.removeItem('eLabNext_link');
        }
    }, [dispatch, location.state]);

    return (
        <DashboardContainer title='Predict'>
            {
                (!loading && status === 'success' && result?.length > 0) &&
                <div className={c.predictValidationContainer}>
                    <div className={c.predictValidationContent}>
                        <div className={c.predictValidationPart}>
                            {result.map((i, k) => {
                                return <div key={k} className={c.predictValidationElem}>
                                    <div className={c.predictValidationTitle}>
                                        <h5>
                                        <span style={{
                                            color: '#034459',
                                            paddingLeft: '9px',
                                            fontStyle: 'italic'
                                        }}>{i["group_name"]}</span>
                                        </h5>
                                    </div>
                                    <div className={c.validationResultInfo}>
                                        <p><span>Assay Name - </span> {i["assay_name"]}</p>
                                        <p><span>Status - </span>{i["message"]}</p>
                                        <p><span>Request ID - </span> {i["request_id"]}</p>
                                    </div>
                                </div>
                            })}
                        </div>
                        {(!loading && status === 'success' && !platform_request_id)
                            ? <Link to='/performed'><h5>
                                        <span style={{
                                            color: '#034459',
                                            paddingLeft: '9px',
                                        }}>Show performed tests</span>
                            </h5></Link>
                            : <Link target={'_blank'} to={localStorage.getItem('elabnext_link')}>
                                    <span style={{
                                        color: '#034459',
                                        paddingLeft: '9px',
                                    }}>{localStorage.getItem('elabnext_link')}</span>
                            </Link>}
                    </div>
                </div>
            }
            {
                status === 'error' && status_code !== 200 && !loading &&
                <Message message={message} status={status} status_code={status_code}/>
            }
            {
                loading && <Loading/>
            }
        </DashboardContainer>
    );
};

export default PredictValidationContainer;
