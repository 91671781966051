import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {availableAssaysAction} from '../../redux/toolkit/availableAssaysSlice';
import {chooseTestValidateAction} from '../../redux/toolkit/chooseTestValidateSlice';
import DashboardContainer from '../sideBar/DashboardContainer';
import Message from '../../helper/messages/Message';
import Loading from '../../helper/Loading';
import c from './choosetest.module.css';

const ChooseTestContainer = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const filteredSmilesFromNewTest = location.state?.filter(str => str.trim().length).map(str => str.trim());
    const availableAssaysResult = useSelector(state => state?.availableAssaysReducer);
    const {loading} = availableAssaysResult
    const {assays, status, message, status_code, subscription_status} = availableAssaysResult?.availableAssaysResult;
    const [checkboxSmilesValidate, setCheckboxSmilesValidate] = useState([]);

    useEffect(() => {
        if (location.state) {
            dispatch(availableAssaysAction({'service_type': 'subscribed'}));
        }
    }, [dispatch, location.state]);

    const handleChange = (e) => {
        if (checkboxSmilesValidate.includes(e.target.value)) {
            setCheckboxSmilesValidate(checkboxSmilesValidate.filter((s) => s !== e.target.value));
        } else {
            setCheckboxSmilesValidate([...checkboxSmilesValidate, e.target.value]);
        }
    };

    const handleClick = () => {
        if (checkboxSmilesValidate?.length > 0) {
            const chooseTestValidateForm = {
                assays: checkboxSmilesValidate,
                smiles: filteredSmilesFromNewTest,
                platform_id: 'b5c052ca-a48b-4f90-94dd-4feba49353d3',
            }
            dispatch(chooseTestValidateAction(chooseTestValidateForm));
        }
    };

    return (
        <DashboardContainer title='Choose Test'>
            {
                (subscription_status && subscription_status === 'ok' && !loading && status === 'success' && assays?.length > 0) &&
                <div className={c.chooseTestContainer}>
                    <div className={c.chooseTestContent}>
                        {assays.map((i, k) => {
                            return <div key={k} className={c.chooseTestContentChild}>
                                <h5 style={{textAlign: 'center', fontSize: '20px'}} className={c.chooseTestH5}>
                                    <span style={{color: '#034459', paddingLeft: '5px'}}>{i?.group_name}</span>
                                </h5>
                                {
                                    i.data?.length > 0 && i.data.map((elem, key) => {
                                        return <div key={key} className={c.chooseTestElem}>
                                            <input type='checkbox' value={elem.identifier} onChange={handleChange}/>
                                            <label> {elem.name}</label>
                                        </div>
                                    })
                                }
                            </div>
                        })}
                    </div>
                    <div className={c.chooseTestButton}>
                        <Link to='/validationResult'>
                            <button disabled={checkboxSmilesValidate?.length === 0}
                                    style={{
                                        background: checkboxSmilesValidate?.length === 0 ? 'silver' : '#005773'
                                    }}
                                    onClick={handleClick}> Next
                            </button>
                        </Link>
                    </div>
                </div>
            }
            {
                ((!loading && status !== 'error' && !assays?.length)
                    || (!loading && status !== 'error' && subscription_status && subscription_status !== 'ok'))
                && <Message message={message}/>
            }
            {status === 'error' && status_code !== 200 && !loading &&
                <Message message={message} status={status} status_code={status_code}/>
            }
            {
                loading && <Loading/>
            }
        </DashboardContainer>
    );
};

export default ChooseTestContainer;
