import axios from 'axios';
import {API} from '../../api/api';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';

export const activateAccountAction = createAsyncThunk(
    'activateAccountAction',
    async function (confirm_id, {rejectWithValue}) {
        try {
            const response = await axios.post(`${API.BASE_URL}/activate_account`,
                JSON.stringify(confirm_id), {
                    withCredentials: true,
                    headers: {
                        'Accept': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        'Content-Type': 'application/json',
                    }
                });
            const {data, status} = response;
            if (status === 200 && data.status === 'success' && data?.message) {
                return response.data
            } else {
                return rejectWithValue(response.data);
            }
        } catch (e) {
            return {message: e?.message || 'Something wnt wrong, please try later'}
        }
    }
);

const activateAccountSlice = createSlice({
    name: 'ACTIVATE_ACCOUNT_TOOLKIT',
    initialState: {
        loading: null,
        data: null,
        error: null,
    },
    reducers: {},
    extraReducers: {
        [activateAccountAction.pending]: (state) => {
            state.error = null;
            state.loading = true;
            state.data = null;
        },
        [activateAccountAction.fulfilled]: (state, action) => {
            state.data = action.payload;
            state.loading = false;
            state.error = null;
        },
        [activateAccountAction.rejected]: (state, action) => {
            state.data = action.payload;
            state.loading = false;
            state.error = action.payload?.message || 'Something is wrong, please try later.';
        },
    }
});

export default activateAccountSlice.reducer;
