import React, {useContext} from 'react';
import {Link} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {AuthContext} from '../../auth/AuthContext';
import {logoutAction} from "../../redux/toolkit/loginSlice";
import {
    People,
    Person,
    Collection,
    ChevronRight,
    Power,
    Bezier,
    MenuDown,
    CashCoin,
    WindowDock 
} from 'react-bootstrap-icons';
import c from './dashboard.module.css';

const SideBar = () => {

    const dispatch = useDispatch();
    const auth = useContext(AuthContext);
    const logOut = () => {
        dispatch(logoutAction())
        auth.logOut();
    }

    return (
        <div className={c.sideBarDashboard}>
            <div className={c.fixedContent}>

                <div className={c.sideBarUserContent}>
                    <div className={c.sideBarItem}>
                        <div className={c.sideBarItemIcon}><Person size={28}/></div>
                        <div className={c.sideBarItemTitle} title='Account'>
                            {auth?.account_name}
                        </div>
                        <div className={c.logoutButton} onClick={logOut} title='Logout'>
                            <Power size={25}/>
                        </div>
                    </div>
                </div>

                <div className={c.sideBarOptions}>
                    <Link to='/available' title={'Available test'}>
                        <div className={c.sideBarOptionsItem}>
                            <span className={c.sideBarOptionsIcon}><Collection size={28}/></span>
                            <span className={c.sideBarOptionsTitle}>Available Tests</span>
                            <span className={c.sideBarOptionsIconRight}><ChevronRight size={15}/></span>
                        </div>
                    </Link>
                    <Link to='/performed' title={'Performed Tests'}>
                        <div className={c.sideBarOptionsItem}>
                            <span className={c.sideBarOptionsIcon}><MenuDown size={28}/></span>
                            <span className={c.sideBarOptionsTitle}>Performed Tests</span>
                            <span className={c.sideBarOptionsIconRight}><ChevronRight size={15}/></span>
                        </div>
                    </Link>
                    <Link to='/newTest' title={'New Test'}>
                        <div className={c.sideBarOptionsItem}>
                            <span className={c.sideBarOptionsIcon}><Bezier size={28}/></span>
                            <span className={c.sideBarOptionsTitle}>New Test</span>
                            <span className={c.sideBarOptionsIconRight}><ChevronRight size={15}/></span>
                        </div>
                    </Link>
                    <Link to='/personalInformation' title={'Personal Information'}>
                        <div className={c.sideBarOptionsItem}>
                            <span className={c.sideBarOptionsIcon}><People size={28}/></span>
                            <span className={c.sideBarOptionsTitle}>Personal Information</span>
                            <span className={c.sideBarOptionsIconRight}><ChevronRight size={15}/></span>
                        </div>
                    </Link>
                    <Link to='/invoices' title={'Request Invoice'}>
                        <div className={c.sideBarOptionsItem}>
                            <span className={c.sideBarOptionsIcon}><CashCoin size={28}/></span>
                            <span className={c.sideBarOptionsTitle}>Request Invoice</span>
                            <span className={c.sideBarOptionsIconRight}><ChevronRight size={15}/></span>
                        </div>
                    </Link>
                    <Link to='/termsOfUse' title={'Terms and Conditions'}>
                        <div className={c.sideBarOptionsItem}>
                            <span className={c.sideBarOptionsIcon}><WindowDock size={28}/></span>
                            <span className={c.sideBarOptionsTitle}>Terms and Conditions</span>
                            <span className={c.sideBarOptionsIconRight}><ChevronRight size={15}/></span>
                        </div>
                    </Link>
                </div>
                <div className={c.sideBarBottomLogo}>
                    <a rel="noreferrer" href='https://www.toxometris.ai/' target='_blank'>
                        <img className={c.imageLogo} src="/img/Logo2.png" alt="logo"/>
                    </a>
                    <span style={{color: 'silver', letterSpacing: '0.09rem'}}>support@toxometris.ai</span>
                </div>
            </div>
        </div>
    );
};

export default SideBar;
