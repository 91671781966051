import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {personalInformationAction} from '../../redux/toolkit/personalInformationSlice';
import DashboardContainer from '../sideBar/DashboardContainer';
import BootstrapTable from 'react-bootstrap-table-next';
import Message from '../../helper/messages/Message';
import Loading from '../../helper/Loading';
import ChangePassword from './ChangePassword';
import {columnsTable} from './columnsTable';
import {Key, Pen} from 'react-bootstrap-icons';
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
    SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import {updateAccountAction} from "../../redux/toolkit/updateAccountSlice";
import Select from "react-select";
import countryList from "react-select-country-list";
import c from './personalInformation.module.css';

const PersonalInformation = () => {

    const dispatch = useDispatch();
    const optionsCountry = useMemo(() => countryList().getData(), []);
    const data = useSelector(state => state.updateAccountReducer);
    const personalInformationResult = useSelector(state => state?.personalInformationReducer);
    const {loading} = personalInformationResult;
    const {user_info, status_code, status, message} = personalInformationResult?.personalInformationResult;

    // const {loading, error, result} = data;

    const [showForm, setShowForm] = useState('');
    const [renderPage, setRenderPage] = useState(false);
    const [updateAccount, setUpdateAccount] = useState({
        company_name: '',
        first_name: '',
        last_name: '',
        position: '',
        company_address: '',
        country: '',
        tax_number: '',
    });
    useEffect(() => {
        setUpdateAccount({
            company_name: user_info?.company_name || '',
            first_name: user_info?.first_name || '',
            last_name: user_info?.last_name || '',
            position: user_info?.position || '',
            company_address: user_info?.company_address || '',
            country: user_info?.country || '',
            tax_number: user_info?.tax_number || '',
        })
    }, [user_info])

    useEffect(() => {
        dispatch(personalInformationAction());
        setShowForm('');
    }, [renderPage, dispatch]);

    const handleChange = (e, name) => {
        if (name && name === 'country') {
            setUpdateAccount({...updateAccount, country: e.label});
        }
        if (!name || name !== 'country') {
            setUpdateAccount({...updateAccount, [e.target.name]: e.target.value});
        }
        if (renderPage) {
            isRender(false);
        }
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        const isAnyFieldNotEmpty = Object.values(updateAccount).some(value => value.trim().length > 0);
        if (isAnyFieldNotEmpty) {
            dispatch(updateAccountAction(updateAccount))
        }
        isRender(true);
    }

    const isRender = (render) => {
        setRenderPage(render);
    }
    const options = {
        custom: true,
        totalSize: user_info?.subscriptions?.length
    };

    const customStyles = {
        control: base => ({
            ...base,
            height: 1,
            minHeight: 28,
            fontSize: 10
        })
    };

    return (
        <DashboardContainer title='Personal Information'>
            {
                (!loading && status === 'success' && user_info) &&
                <div className={c.personalInformationContainer}>
                    <div className={c.personalInformation}>
                        <div className={c.personalInfoContent}>
                            <div className={c.personalInfoElements}>
                                <div className={c.personalLogo}>
                                    <img src='/img/logo-user-icon-png.png' alt='userLogo'/>
                                </div>
                                <div className={`${c.formBarButtons} ${c.personalInfoBtn}`}>
                                    <div title={'Edit Account'} onClick={() => setShowForm('updateAccount')}
                                         className={c.pencilIconContainer}>
                                        <Pen size={22}/>
                                    </div>
                                    <div title={'Change Password'} className={c.pencilIconContainer}
                                         onClick={() => setShowForm('changePass')}>
                                        <Key size={22}/>
                                    </div>
                                </div>
                            </div>
                            <div className={c.accountInfoContent}>
                                <form onSubmit={handleSubmit} className={c.insideFormContent}>
                                    <div style={{width: '350px'}}>
                                        <div className={c.accountInfoEl}>
                                            <span>First Name :</span>
                                            {showForm === 'updateAccount'
                                                ? <input type="text"
                                                         name='first_name'
                                                         className={c.inputField}
                                                         value={updateAccount?.first_name || ''}
                                                         onChange={(e) => handleChange(e)}
                                                />
                                                : <p>{user_info.first_name}</p>}
                                        </div>
                                        <div className={c.accountInfoEl}>
                                            <span>Last Name :</span>
                                            {showForm === 'updateAccount'
                                                ? <input type="text"
                                                         name='last_name'
                                                         className={c.inputField}
                                                         value={updateAccount.last_name || ''}
                                                         onChange={(e) => handleChange(e)}/>
                                                : <p>{user_info.last_name}</p>}
                                        </div>
                                        <div className={c.accountInfoEl}>
                                            <span>Email :</span>
                                            <p>{user_info.username}</p>
                                        </div>
                                        <div className={c.accountInfoEl}>
                                            <span>Company :</span>
                                            {showForm === 'updateAccount'
                                                ?
                                                <input type="text"
                                                       name='company_name'
                                                       className={c.inputField}
                                                       value={updateAccount.company_name || ''}
                                                       onChange={(e) => handleChange(e)}/>
                                                : <p>{user_info.company_name}</p>}
                                        </div>
                                        <div className={c.accountInfoEl}>
                                            <span>Position :</span>
                                            {showForm === 'updateAccount'
                                                ? <input type="text"
                                                         name='position'
                                                         className={c.inputField}
                                                         value={updateAccount.position || ''}
                                                         onChange={(e) => handleChange(e)}/>
                                                : <p>{user_info.position}</p>}
                                        </div>
                                        <div className={c.accountInfoEl}>
                                            <span>TIN :</span>
                                            {showForm === 'updateAccount'
                                                ? <input type="text"
                                                         name='tax_number'
                                                         className={c.inputField}
                                                         value={updateAccount.tax_number || ''}
                                                         onChange={(e) => handleChange(e)}/>
                                                : <p>{user_info.tax_number}</p>}
                                        </div>
                                        <div className={`${c.accountInfoEl} ${c.selectCountry}`}>
                                            <span style={{minWidth: '100px'}}>Country :</span>
                                            {showForm === 'updateAccount'
                                                ? <Select
                                                    styles={customStyles}
                                                    options={optionsCountry}
                                                    value={updateAccount?.country ? {label: updateAccount?.country} : ''}
                                                    placeholder={'Select Country'}
                                                    onChange={(e) => handleChange(e, 'country')}/>
                                                : <p>{user_info.country}</p>}
                                        </div>
                                    </div>
                                    <div className={c.personalInfoBtn}>
                                        <div className={c.closeEditButton}>
                                            {showForm === 'updateAccount' && <button>Update Account</button>}
                                            {showForm === 'updateAccount' && <button onClick={() => setShowForm('')}>Cancel</button>}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className={c.footerPersonalInfo}>
                            <div className={c.personalInformationHeader}>
                                {
                                    showForm === 'changePass' && <ChangePassword username={user_info?.username || ''}/>
                                }
                                {
                                    !data?.loading && !data?.error && data?.result?.message &&
                                    <span className={c.successSpan}> {data?.result.message} </span>
                                }
                                {
                                    (!data?.loading && data?.error && !data?.result?.message) &&
                                    <span className={c.mistakeSpan}>{data?.error.message}</span>
                                }
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div>
                        <h5 style={{textAlign: 'center', color: '#005773'}}>
                            Active Subscriptions
                        </h5>
                        {(!loading && user_info?.subscriptions?.length > 0) &&
                            <div>
                                <PaginationProvider pagination={paginationFactory(options)}>
                                    {({paginationProps, paginationTableProps}) => {
                                        return <div>
                                            <div className={`${c.personalTableContent} performedContent`}>
                                                <BootstrapTable
                                                    keyField='id'
                                                    data={user_info.subscriptions}
                                                    columns={columnsTable()}
                                                    {...paginationTableProps}
                                                />
                                            </div>
                                            <div className={c.sizePagination}>
                                                <SizePerPageDropdownStandalone {...paginationProps}/>
                                                <PaginationListStandalone {...paginationProps}/>
                                            </div>
                                        </div>
                                    }}
                                </PaginationProvider>
                            </div>
                        }
                    </div>
                </div>
            }
            {
                !loading && status !== 'error' && !user_info?.subscriptions?.length && <Message message={message}/>
            }
            {
                status === 'error' && status_code !== 200 && !loading &&
                <Message message={message} status={status} status_code={status_code}/>
            }
            {
                loading && <Loading/>
            }

        </DashboardContainer>
    );
};

export default PersonalInformation;
