import {useCallback, useEffect, useState} from 'react';

export const AuthHook = () => {

    const [access_token_toxometris, setAccessToken] = useState(localStorage.getItem('access_token_toxometris') || null);
    const [account_name, setAccountName] = useState(localStorage.getItem('account_name_toxometris') || null);

    const login = useCallback((access_token_toxometris, account_name) => {
        setAccessToken(access_token_toxometris);
        setAccountName(account_name);
        localStorage.setItem('access_token_toxometris', access_token_toxometris);
        localStorage.setItem('account_name_toxometris', account_name);
    }, []);

    const logOut = useCallback(() => {
        setAccessToken(null);
        localStorage.removeItem('platform_request_id');
        localStorage.removeItem('requestToken');
        localStorage.removeItem('eLabNext_link');
        localStorage.removeItem('noLink');
        localStorage.removeItem('access_token_toxometris');
        localStorage.removeItem('account_name_toxometris');
        // window.location.href = window.location.origin + "/";
    }, []);

    useEffect(() => {
        const token = localStorage.getItem('access_token_toxometris');
        const account_name = localStorage.getItem('account_name_toxometris');
        if (token) {
            login(token, account_name);
        }
    }, [login]);

    return {login, logOut, account_name, access_token_toxometris}
};
