import React, {useEffect, useMemo, useState} from 'react';
import {createAccountAction} from '../../redux/toolkit/createAccountSlice';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import ReCAPTCHA from "react-google-recaptcha";
import Loading from "../../helper/Loading";
import c from './createaccount.module.css';

let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*-]{8,}$/

const CreateAccountContainer = () => {

    const dispatch = useDispatch();
    const createAccount = useSelector(state => state?.createAccountReducer);
    const {loading, error, createAccountResult} = createAccount;

    const options = useMemo(() => countryList().getData(), []);

    const [mistake, setMistake] = useState('');
    const [passIsValid, setPassIsValid] = useState(false);
    const [validCaptcha, setValidCaptcha] = useState(false);
    const [createAccountForm, setCreateAccountForm] = useState({
        company: '',
        company_address: '',
        first_name: '',
        last_name: '',
        password: '',
        repeat_password: '',
        position: '',
        tax_number: '',
        username: '',
        country: '',
    });

    useEffect(() => {
        if (createAccountForm.password && regex.test(createAccountForm.password)) {
            setPassIsValid(true);
            setMistake('');
        } else {
            setPassIsValid(false);
        }
    }, [createAccountForm.password]);


    const onChangeCaptcha = (value) => {
        setValidCaptcha(value);
    }

    const handelChange = (e, name) => {

        if (name && name === 'country') {
            setCreateAccountForm({...createAccountForm, country: e.label})
        }
        if (!name || name !== 'country') {
            setCreateAccountForm({...createAccountForm, [e.target.name]: e.target.value})
        }
        if (mistake) {
            setMistake('');
        }

        setValidCaptcha(false);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (createAccountForm.password === createAccountForm.repeat_password && passIsValid) {
            setMistake('');
            delete createAccountForm.repeat_password;
            dispatch(createAccountAction({createAccountForm}));
        } else if (createAccountForm.password !== createAccountForm.repeat_password && passIsValid) {
            setMistake("Passwords don't match, please try again");
        } else {
            setMistake('Password must be at least 8 characters, including at least one number, one uppercase, and one lowercase letter. The password may also include the following special characters @#$%^&*-');
        }
    }

    return (
        <div className={c.createAccountContainer}>
            <div className={c.createAccountContent}>
                <div className={c.buttonContainerCA}>
                    <div className={c.loginBtn}>
                        <Link to='/'>
                            <button>Login</button>
                        </Link>
                    </div>
                    <div className={c.createAccountBtn}>
                        <Link to='/createAccount'>
                            <button>Sign Up</button>
                        </Link>
                    </div>
                </div>
                <div className={c.formCA}>
                    <form onSubmit={handleSubmit}>
                        <div className={c.formParts}>
                            <div className={c.formLeftPart}>

                                <div className={c.inpElem}>
                                    <input value={createAccountForm.first_name || ''}
                                           placeholder='First Name'
                                           name='first_name'
                                           type='text'
                                           onChange={(e) => handelChange(e)}
                                           className={c.inputField}
                                           required/>
                                </div>
                                <div className={c.inpElem}>
                                    <input value={createAccountForm.last_name || ''}
                                           placeholder='Last Name'
                                           name='last_name'
                                           type='text'
                                           className={c.inputField}
                                           onChange={(e) => handelChange(e)}
                                           required/>
                                </div>
                                <div className={c.inpElem}>
                                    <input value={createAccountForm.username || ''}
                                           placeholder='Email'
                                           name='username'
                                           type='email'
                                           className={c.inputField}
                                           onChange={(e) => handelChange(e)}
                                           required/>
                                </div>
                                <div className={c.inpElem}>
                                    <Select required
                                            options={options}
                                            value={createAccountForm.country ? {label: createAccountForm.country} : ''}
                                            placeholder={'Select Country'}
                                            onChange={(e) => handelChange(e, 'country')}/>
                                </div>
                            </div>

                            <div className={c.formRightPart}>
                                <div className={c.inpElem}>
                                    <input value={createAccountForm.company || ''}
                                           placeholder='Company *'
                                           name='company'
                                           type='text'
                                           className={c.inputField}
                                           onChange={(e) => handelChange(e)}
                                           required/>
                                </div>
                                <div className={c.inpElem}>
                                    <input value={createAccountForm.company_address || ''}
                                           placeholder='Company Address *'
                                           name='company_address'
                                           type='text'
                                           className={c.inputField}
                                           onChange={(e) => handelChange(e)}
                                           required/>
                                </div>
                                <div className={c.inpElem}>
                                    <input value={createAccountForm.position || ''}
                                           placeholder='Position'
                                           name='position'
                                           type='text'
                                           className={c.inputField}
                                           onChange={(e) => handelChange(e)}
                                           required/>
                                </div>
                                <div className={c.inpElem}>
                                    <input value={createAccountForm.tax_number || ''}
                                           placeholder='Tax-id *'
                                           name='tax_number'
                                           type='text'
                                           className={c.inputField}
                                           onChange={(e) => handelChange(e)}
                                           required/>
                                </div>
                            </div>
                        </div>
                        <span style={{color: 'silver'}}>* mentioned fields will appear in the invoice</span>
                        <div className={c.inpElem} style={{paddingTop: '25px'}}>
                            <input value={createAccountForm.password || ''}
                                   placeholder='Password'
                                   name='password'
                                   type='password'
                                   autoComplete='new-password'
                                   className={c.inputField}
                                   onChange={(e) => handelChange(e)}
                                   required/>
                        </div>
                        <div className={c.inpElem}>
                            <input value={createAccountForm.repeat_password || ''}
                                   name='repeat_password'
                                   placeholder='Repeat Password'
                                   type='password'
                                   autoComplete='new-password'
                                   className={c.inputField}
                                   onChange={(e) => handelChange(e)}
                                   required/>
                        </div>
                        {
                            !loading &&
                            <div className={c.submitBtnCA}>
                                <button disabled={!validCaptcha} style={{cursor: !validCaptcha ? 'not-allowed' : 'pointer'}}
                                        title={!validCaptcha ? 'Please select captcha' : 'Create Account'}
                                        type='submit'>
                                    Create Account
                                </button>
                                <div className={c.reCAPTCHAContainer}>
                                    {
                                        (!loading && !mistake && !error?.message) &&
                                        <ReCAPTCHA sitekey={window.REACT_APP_SITE_KEY}
                                                   onChange={onChangeCaptcha}
                                        />
                                    }
                                </div>
                            </div>
                        }
                        {
                            mistake && <span className={c.mistakeSpan}> {mistake} </span>
                        }
                        {
                            (!loading && error?.message) && <span className={c.mistakeSpan}> {error?.message} </span>
                        }
                        {
                            (!loading && !error?.message && !mistake && createAccountResult?.message) &&
                            <span className={c.accountCreated}> {createAccountResult?.message} </span>
                        }
                        {
                            loading && <Loading color={'silver'}/>
                        }
                    </form>
                </div>
                <div className={c.loginFormFooter}>
                    <a rel='noreferrer' href='https://www.toxometris.ai/' target='_blank'>
                        <img src={'/img/Logo2.png'} alt='logo'/>
                    </a>
                </div>

            </div>
        </div>
    );
};
export default CreateAccountContainer;


