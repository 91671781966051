import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import DashboardContainer from "../sideBar/DashboardContainer";
import { useNavigate } from "react-router-dom";
import Loading from "../../helper/Loading";
import Message from "../../helper/messages/Message";
import c from "./validationResult.module.css";

const ValidationResult = () => {
  let navigate = useNavigate();
  const chooseTestValidateResult = useSelector(
    (state) => state?.chooseTestValidateReducer
  );
  const { result, status, message, status_code } =
    chooseTestValidateResult?.validateResult;
  const { platformRequestId, loading } = chooseTestValidateResult;
  const totalPrice = useRef(0);
  const [isUploaded, setIsUploaded] = useState(false);

  const handleUploadReportsChange = () => {
    setIsUploaded(!isUploaded);
  };

  const handleClick = () => {
    let smilesArr = [];
    result.forEach((i, k) => {
      smilesArr.push({
        assay_name: i.assay_name,
        valid_smiles: i["valid_smiles"],
        invalid_smiles: i["invalid_smiles"],
      });
    });
    const requestData = {
      is_upload: isUploaded,
      smiles: smilesArr,
      platform_id: "b5c052ca-a48b-4f90-94dd-4feba49353d3",
    };
    navigate("/predict", { state: requestData });
  };

  const isAllValidSmilesEmpty = result?.every(
    (item) => item.valid_smiles.length === 0
  );

  return (
    <DashboardContainer title="Validation Result">
      {!loading && status === "success" && result?.length > 0 && (
        <div className={c.validationResultContainer}>
          <div className={c.validationResultContent}>
            <div className={c.validationResultPart}>
              {result.map((i, k) => {
                totalPrice.current += i["total_price"];
                return (
                  <div key={k} className={c.validationResultElem}>
                    <div className={c.validationResultTitle}>
                      <h5>
                        <span
                          style={{
                            color: "#034459",
                            paddingLeft: "9px",
                            fontStyle: "italic",
                          }}
                        >
                          {i["assay_title"]}
                        </span>
                      </h5>
                    </div>
                    <div className={c.validationResultInfo}>
                      <p>
                        <span>{i["num_invalid"]}</span> - Invalid SMILES
                      </p>
                      <p>
                        <span>{i["num_duplicate"]}</span> - Duplicates SMILES
                      </p>
                      <p>
                        <span>{i["num_salt_mixture"]}</span> - Salts & mixtures
                      </p>
                      <p>
                        <span>{i["num_too_long"]}</span> - Too long SMILES
                        string
                      </p>
                      <p>
                        <span>{i["num_valid"]}</span> - Valid SMILES
                      </p>
                      <p>
                        <span>{i["num_inorganic"]}</span> - Inorganic
                      </p>
                      {i["total_price"] ? (
                        <p>
                          <span>{i["total_price"]} USD</span> - Price
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            {platformRequestId && (
              <div className={c.uploadReports}>
                <input
                  type="checkbox"
                  checked={isUploaded}
                  onChange={handleUploadReportsChange}
                />
                <p>Upload reports to eLabNext File Storage</p>
              </div>
            )}
            <div className={c.validationResultButton}>
              <button
                disabled={isAllValidSmilesEmpty}
                style={{
                  background: isAllValidSmilesEmpty ? "silver" : "#005773",
                }}
                onClick={handleClick}
              >
                Submit
              </button>
              <div className={c.totalPrice}>
                {totalPrice.current > 0 && (
                  <span>Total Price : {totalPrice.current} USD</span>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {status === "error" && status_code !== 200 && !loading && (
        <Message message={message} status={status} status_code={status_code} />
      )}
      {loading && <Loading text={"Validating..."} />}
    </DashboardContainer>
  );
};

export default ValidationResult;
