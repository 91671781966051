import axios from 'axios';
import {API} from '../../api/api';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {parseJwt_Expired} from '../../helper/parseJwtExpired';

export const updateAccountAction = createAsyncThunk(
    'updateAccountAction',
    async function (params, {rejectWithValue}) {
        const access_token_toxometris = localStorage.getItem('access_token_toxometris');
        access_token_toxometris && parseJwt_Expired(access_token_toxometris);

        try {
            const response = await axios.post(`${API.BASE_URL}/update_personal_info`,
                JSON.stringify(params), {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${access_token_toxometris}`,
                    }
                });
            const {data, status} = response;
            if (status === 200 && data.status === 'success') {
                return response.data
            } else {
                return rejectWithValue(response.data);
            }
        } catch (error) {
            return rejectWithValue('Something is wrong, please try later.');
        }
    }
)


const updateAccountSlice = createSlice(
    {
        name: 'UPDATE_ACCOUNT_TOOLKIT',
        initialState: {
            loading: false,
            error: null,
            result: null,
            status: null
        },
        reducers: {},
        extraReducers: {
            [updateAccountAction.pending]: (state) => {
                state.loading = true;
            },
            [updateAccountAction.fulfilled]: (state, action) => {
                state.loading = false;
                state.result = action.payload;
                state.status = 200;
            },
            [updateAccountAction.rejected]: (state, action) => {
                state.loading = false;
                state.result = null;
                state.error = action.payload;
                state.status = 400;
            },
        }
    }
)

export default updateAccountSlice.reducer;
