import {createContext} from 'react';

function noop() {}

export const AuthContext = createContext({
    access_token: null,
    account_name: null,
    login: noop,
    logOut: noop,
    isAuthentication: false
})
