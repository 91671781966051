import React from 'react';
import c from "./makePayment.module.css";

const SmilesContent = ({elem, selectSmiles, handleChangeSmiles}) => {
    return (
        <div className={c.selectSmiles}>
            <div className={c.description}>
                <h5>
                    <span className={c.descriptionElem}>{elem?.name}</span>
                </h5>
                <span>{elem?.description}</span>
            </div>
            <div className={`${c.price} ${c.checkbox}`}>
                <input checked={selectSmiles.includes(elem.identifier)} type='checkbox'
                       value={elem.identifier} onChange={handleChangeSmiles}/>
            </div>
        </div>
    );
};

export default SmilesContent;