import React from 'react';
import c from './healper.module.css';

const Loading = ({text = 'Loading...', color}) => {
    return (
        <div className={c.loadingContainer}>
            <span style={{color:color ? color : '#005773'}}>{text}</span>
        </div>
    );
};

export default Loading;
