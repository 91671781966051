import React from 'react';
import Header from "../header/Header";
import SideBar from "./SideBar";
import c from './dashboard.module.css';

const DashboardContainer = (props) => {
    return (
        <div className={c.dashboardContainer}>
            <div>
                <SideBar/>
            </div>
            <div className={c.dashboardTableContent}>
                <div className={c.fullContent}>
                    <Header search={false} title={props.title}/>
                </div>
               <div className={c.propsChildDashboard}>{props.children}</div>
            </div>
        </div>
    );
};

export default DashboardContainer;
