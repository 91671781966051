import axios from 'axios';
import {API} from '../../api/api';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {parseJwt_Expired} from '../../helper/parseJwtExpired';

export const performedTestsAction = createAsyncThunk(
    'performedTestsAction',
    async function () {
        try {
            const access_token_toxometris = localStorage.getItem('access_token_toxometris');
            access_token_toxometris && parseJwt_Expired(access_token_toxometris);

            const config = {
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token_toxometris}`,
                    'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
                }
            }
            const response = await axios.post(`${API.BASE_URL}/get_performed_tests`, {}, config);
            return response.data
        } catch (e) {
            return {message: e?.message || 'Something wnt wrong, please try later'}
        }
    })

const performedTestsSlice = createSlice({
    name: 'PERFORMED_TESTS_TOOLKIT',
    initialState: {
        loading: null,
        performedTestsResult: [],
        error: null,
        status: null
    },
    reducers: {},
    extraReducers: {
        [performedTestsAction.pending]: (state) => {
            state.status = 'PERFORMED_TESTS_REQUEST';
            state.error = null;
            state.loading = true;
        },

        [performedTestsAction.fulfilled]: (state, action) => {
            state.status = 'PERFORMED_TESTS_SUCCESS';
            state.performedTestsResult = action.payload;
            state.loading = false;
            state.error = null;
        },

        [performedTestsAction.rejected]: (state, action) => {
            state.status = 'PERFORMED_TESTS_FAIL';
            state.loading = false;
            state.error = action.payload || {message: 'ERROR !!!'};
        },
    }
})

export default performedTestsSlice.reducer;
// export const {logoutAction, emptyData} = loginSlice.actions;
