import React, {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {loginFetchAction} from '../../redux/toolkit/loginSlice';
import {activateAccountAction} from '../../redux/toolkit/activateAccountSlice';
import {AuthContext} from '../../auth/AuthContext';
import {Link} from 'react-router-dom';
import c from './login.module.css';

const LoginContainer = () => {

    const dispatch = useDispatch();
    const auth = useContext(AuthContext);
    const authResult = useSelector(state => state?.loginReducer);
    const activateAccountResult = useSelector(state => state?.activateAccountReducer);

    const [loginData, setLoginData] = useState({
        username: '', password: ''
    });

    useEffect(() => {
        const confirmid = new URLSearchParams(window.location.search).get('confirmid');
        if (confirmid && !activateAccountResult?.data?.status_code) {
            dispatch(activateAccountAction({confirm_id: confirmid}));
        }
    }, [dispatch, activateAccountResult]);

    const handelChange = (e) => {
        e.preventDefault();
        setLoginData({...loginData, [e.target.name]: e.target.value});
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if (loginData.username.length > 0 && loginData.password.length > 0) {
            dispatch(loginFetchAction({loginData, authLogin: auth.login}));
        }
    }

    return (
        <div className={c.LoginContainer}>
            <div className={c.loginContent}>
                <div className={c.buttonContainerLogin}>
                    <div className={c.loginBtn}>
                        <Link to='/'>
                            <button>Login</button>
                        </Link>
                    </div>
                    <div className={c.createAccountBtn}>
                        <Link to='/createAccount'>
                            <button>Sign Up</button>
                        </Link>
                    </div>
                </div>

                <div className={c.formLogin}>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <h4>Email</h4>
                            <input value={loginData.username}
                                   placeholder='Email or Username'
                                   name='username'
                                   type='text'
                                   onChange={handelChange}
                                   className={c.inputField}
                                   required/>
                        </div>
                        <div>
                            <h4>Password</h4>
                            <input value={loginData.password}
                                   autoComplete="off"
                                   placeholder='Password'
                                   name='password'
                                   type='password'
                                   onChange={handelChange}
                                   className={c.inputField}
                                   required/>
                        </div>
                        <div className={c.loginBtnSubmit}>
                            <button type='submit'>Login</button>
                            <div className={c.forgotPassword}>
                                <Link to='/forgotPassword'>
                                    <h5>Forgot password</h5>
                                </Link>
                            </div>
                        </div>
                    </form>
                </div>

                {!authResult?.loading && authResult?.error &&
                    <div className={c.errorMessage}>{authResult.error}</div>
                }
                {
                    (Object.keys(authResult?.authResult).length === 0 && !activateAccountResult.loading && activateAccountResult.status === 'error' && !authResult.error
                        && activateAccountResult?.status_code !== 200) && <div className={c.errorMessage}>{activateAccountResult.error.message}</div>
                }
                {
                    (Object.keys(authResult?.authResult).length === 0 && !activateAccountResult.loading && activateAccountResult?.data?.message && !authResult.error
                        && !activateAccountResult?.error && activateAccountResult?.data?.status_code === 200) && <div className={c.successMessage}>{activateAccountResult.data.message}</div>
                }
                {
                    (Object.keys(authResult?.authResult).length === 0 && !activateAccountResult.loading && activateAccountResult?.data?.message && !authResult.error
                        && activateAccountResult?.error && activateAccountResult?.data?.status_code !== 200) && <div className={c.errorMessage}>{activateAccountResult.data.message}</div>
                }
                <div className={c.loginFormFooter}>
                    <a rel='noreferrer' href='https://www.toxometris.ai/' target='_blank'>
                        <img src={'/img/Logo2.png'} alt='logo'/>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default React.memo(LoginContainer);
